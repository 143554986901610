import React, {
  Fragment,
  Suspense,
  useCallback,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Button, CustomInput } from "reactstrap";
import { errorHandler, EventEmitter } from "../helper-methods";
import { getPosts, givenPollOption } from "../http-calls";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  deletePostById,
  fetchPosts,
  getAndUpdateAllCreatorBundles,
  updateAPost,
  updateList,
  updatePostById,
} from "../redux/actions/feedData";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";
import { newSocket } from "../socket-io";
import { postDataListners } from "../config/helper-config";
import ButtonLoading from "../components/ButtonLoading";
// import StoriesSection from "../components/StorySection";
import { getAndUpdateStoriesAsync } from "../redux/actions";
import SuggestedCreatorsCard from "../components/SuggestedCreatorsCard";
import SuggestedLiveEventsCard from "../components/SuggestedLiveEventsCard";

// code splitting
const FeedViewer = React.lazy(() => import("../components/FeedViewer"));

const FeedPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const observer = useRef();
  const divContainerRef = useRef();

  const feedData = useSelector((state) => state?.feedData);
  const userData = useSelector((state) => state?.userData);
  // const {
  //   stories,
  //   storiesNonSub,
  //   liveEvent,
  //   liveEventNonSub,
  //   loading: storiesLoading,
  // } = useSelector((state) => state?.storiesData || []);

  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
    isPurchasedPostsOnly: false,
  });
  const [loading, setLoading] = useState(false);

  const [isNewPost, setIsNewPost] = useState(false);

  // Only for single-influencer Network
  const _redirectToInfluencerDetailsPage = () => {
    if (
      userData?.user?.influencerUsername &&
      userData?.user?.isNetworkSubscribed === false &&
      feedData?.subCount === 0
    ) {
      history.replace(`/influencer/${userData.user.influencerUsername}`);
    }
  };

  const _getAndUpdateAllCreatorBundles = async () => {
    try {
      dispatch(getAndUpdateAllCreatorBundles());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchPosts = async (payload) => {
    try {
      setLoading(true);

      await fetchPosts(payload)(dispatch);

      if (payload.skip === 0) {
        _redirectToInfluencerDetailsPage();
      }

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _getAllStories = async () => {
    try {
      await getAndUpdateStoriesAsync()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _resetDataPayload = () => {
    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    setDataPayload(newDataPayload);
    _fetchPosts(newDataPayload);
  };

  const _updateIndividualPost = (index, limit = 1) => {
    return new Promise((resolve, reject) => {
      getPosts({ skip: index, limit })
        .then(async (response) => {
          dispatch(updateList({ index, post: response.posts[0] }));
          resolve();
        })
        .catch((error) => {
          console.log({ error });
          _resetDataPayload();
          reject();
        });
    });
  };

  const _selectPollOption = async (feed, option, index) => {
    try {
      const data = {
        postId: feed._id,
        optionId: option._id,
      };

      dispatch(
        updateAPost({
          index,
          attr: "_opinions",
          value: [{ option: option._id }],
        })
      );

      await givenPollOption(data);

      await _updateIndividualPost(index);
    } catch (error) {
      _updateIndividualPost(index);
      errorHandler(error);
    }
  };

  const _toggleLikePost = async (index, isLiked, persistPrev) => {
    try {
      if (isLiked) {
        dispatch(updateAPost({ index, attr: "_isLiked", value: 1 }));
        dispatch(
          updateAPost({
            index,
            attr: "likes",
            value: persistPrev
              ? +feedData.feeds[index]["likes"]
              : +feedData.feeds[index]["likes"] + 1,
          })
        );
      } else {
        dispatch(updateAPost({ index, attr: "_isLiked", value: 0 }));
        dispatch(
          updateAPost({
            index,
            attr: "likes",
            value:
              +feedData.feeds[index]["likes"] > 0
                ? persistPrev
                  ? +feedData.feeds[index]["likes"]
                  : +feedData.feeds[index]["likes"] - 1
                : 0,
          })
        );
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // updates comment count of a feed in redux before api call
  const _onCommentPost = (index, isAdded) => {
    try {
      let commentsCount = 0;

      if (isAdded) {
        if (feedData?.feeds?.[index]?.hasOwnProperty("comments")) {
          commentsCount = feedData.feeds[index]["comments"] + 1;
        } else {
          commentsCount = 1;
        }
      } else {
        if (+feedData?.feeds?.[index]?.comments) {
          commentsCount = feedData.feeds[index]["comments"] - 1;
        }
      }

      dispatch(updateAPost({ index, attr: "comments", value: commentsCount }));
    } catch (error) {
      console.log({ error });
    }
  };

  // updates comment count after getAllComment api is hit,
  // value: perticularly used to handle counts of nested comments
  const _updateCommentsCount = ({ index, value }) => {
    dispatch(
      updateAPost({
        index: index,
        attr: "comments",
        value,
      })
    );
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          feedData?.feeds?.length < feedData?.total
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = feedData?.feeds?.length || 0;
          setDataPayload(newDataPayload);
          _fetchPosts(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, feedData?.feeds]
  );

  const _onChangeIsPurchasedPostsOnly = (checked = false) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload.isPurchasedPostsOnly = checked;
    setDataPayload(newDataPayload);
    _fetchPosts(newDataPayload);
  };

  const _listenPostData = () => {
    try {
      postDataListners.forEach((each) => {
        newSocket.on(each.value, (res) => {
          try {
            console.log(`${each.value}: `, { res });

            if (res.doSkip) return;

            if (res.error) {
              errorHandler(res);
              return;
            }

            // const isRemove = !!each.isRemove
            const postId = res.postId;

            if (!postId) {
              console.error(`postId not found!`);
              return;
            }

            switch (each.key) {
              case "post_created": {
                const { isBlocked, isExpired } = res || {};
                if (!isExpired && !isBlocked) setIsNewPost(true);
                break;
              }
              case "post_updated":
              case "like_created":
              case "like_removed": {
                dispatch(updatePostById(postId));
                break;
              }
              case "comment_created":
              case "comment_updated":
              case "comment_deleted": {
                dispatch(updatePostById(postId));

                EventEmitter.dispatch("post_comment_trigger", {
                  postId,
                  interaction: res?.interaction,
                });

                if (res.interaction?.isSubCommnet) {
                  EventEmitter.dispatch("post_subcomment_trigger", {
                    postId,
                    interaction: res?.interaction,
                  });
                }
                break;
              }
              case "post_deleted": {
                dispatch(deletePostById(postId));
                break;
              }
              default: {
                return;
              }
            }
          } catch (error) {
            console.log({ error });
          }
        });
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const _subscribeToPostData = () => {
    try {
      const fanId = userData?.user?._id || userData?._id;

      if (!fanId) {
        console.log({ userData });
        errorHandler({ reason: "fan Id is not found" });
        return;
      }

      newSocket.emit("subscribe", { room: `feed/${fanId}` }, function (res) {
        console.log("subscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });

      _listenPostData();
    } catch (error) {
      console.log({ error });
    }
  };

  const _unsubscribeToPostData = () => {
    try {
      postDataListners.forEach((each) => {
        newSocket.removeAllListeners(each.value);
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const _onClickNewPost = () => {
    try {
      setIsNewPost(false);
      _resetDataPayload();

      divContainerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    _getAndUpdateAllCreatorBundles();

    _fetchPosts(dataPayload);

    _subscribeToPostData();

    _getAllStories();

    return () => {
      _unsubscribeToPostData();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={divContainerRef} className="app customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col
            sm={12}
            md={11}
            lg={8}
            xl={{
              offset: 3,
              size: 6,
            }}
            className="noPadding bg-white"
          >
            {/* <StoriesSection
              stories={stories}
              storiesNonSub={storiesNonSub}
              liveEvent={liveEvent}
              liveEventNonSub={liveEventNonSub}
              loading={storiesLoading}
            /> */}

            <div className="pgTitle customMarginMob feedPgTitle">
              <h2>Home {loading ? <ButtonLoading /> : null}</h2>

              <CustomInput
                id="feedpage_isPurchasedPostsOnly"
                type="checkbox"
                label="Purchased Posts"
                checked={dataPayload.isPurchasedPostsOnly}
                onChange={(e) =>
                  _onChangeIsPurchasedPostsOnly(e.target.checked)
                }
              />
            </div>

            {isNewPost ? (
              <Button
                className="themeBtn saveBtn sticky-top"
                type="submit"
                style={{ position: "sticky" }}
                onClick={() => _onClickNewPost()}
              >
                New Post
              </Button>
            ) : null}

            {/* multiple posts/feeds */}
            {feedData?.feeds?.length ? (
              feedData.feeds.map((feed, feedIndex) => (
                <Fragment key={`feedpage_${feed._id}_${feedIndex}`}>
                  <ErrorBoundary>
                    <Suspense
                      fallback={<SkeletonLoading type={"post"} count={1} />}
                    >
                      <FeedViewer
                        {...(feedIndex === feedData.feeds.length - 1
                          ? { lastElementRef }
                          : {})}
                        feed={feed}
                        feedIndex={feedIndex}
                        feedBundles={feedData?.bundles}
                        onTierUpgrade={() => _updateIndividualPost(feedIndex)}
                        selectPollOption={(feed, option, index) =>
                          _selectPollOption(feed, option, index)
                        }
                        toggleLikePost={_toggleLikePost}
                        onComment={(index, isAdded) =>
                          _onCommentPost(index, isAdded)
                        }
                        onPaymentComplete={(index) =>
                          _updateIndividualPost(index)
                        }
                        loadingFeeds={loading}
                        updateCommentsCount={({ index, value }) =>
                          _updateCommentsCount({ index, value })
                        }
                      />
                    </Suspense>
                  </ErrorBoundary>
                </Fragment>
              ))
            ) : loading || feedData?.subCount ? (
              <>
                {loading ? (
                  <SkeletonLoading type={"post"} count={2} />
                ) : (
                  <div className="noContentFound">No posts found</div>
                )}
              </>
            ) : (
              <Button
                className="customNotificationText"
                onClick={() => {
                  history.push("/search");
                }}
              >
                You have not subscribed to any creators
                <br />
                Search for creators by keyword, name or category
              </Button>
            )}
          </Col>
          <Col xl={3} className="d-none d-xl-block">
            <div className="advertiseArea">
              <SuggestedCreatorsCard />

              <SuggestedLiveEventsCard />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeedPage;
