import React, { useRef, useState } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { errorHandler } from "../helper-methods";
import { showLoader, hideLoader } from "../redux/actions/loaderData";
import { updateOnlyUserData } from "../redux/actions/userData";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  fetchPPVDetails,
  increaseViewCount,
  createPaymentIntent,
} from "../http-calls";
import ProcessPayment from "../payment/index";
import VisibilitySensor from "react-visibility-sensor";
import ConfigureString from "../components/ConfigureString";
import PaymentTierComponent from "../components/PaymentTierComponent";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomLoader from "../components/custom/CustomLoader";
import { useEffect } from "react";
import { getAndUpdateAllCreatorBundles } from "../redux/actions";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import ErrorBoundary from "../components/ErrorBoundary";
import AudioPlayer from "../components/custom/AudioPlayer";
import CustomVideoPlayer from "../components/custom/CustomVideoPlayer";
import SvgIcons from "../components/SvgIcons";

const PayPerViewDetailsPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);
  const feedData = useSelector((state) => state?.feedData);

  const previewVideoTimerRef = useRef({ current: null });

  const [ppvData, setPpvData] = useState(null);
  const [purchaseCount, setPurchaseCount] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [isPaid, setIsPaid] = useState(0);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [viewCountApiCall, setViewCountApiCall] = useState(false);
  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const [paymentApiData, setPaymentApiData] = useState({
    url3DSecure: "/payment/intent/ppv",
    url: "/payment/ppv",
    payload: {
      ppvId: params?.id,
    },
  });

  const _getAndUpdateAllCreatorBundles = async () => {
    try {
      dispatch(getAndUpdateAllCreatorBundles());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchPPVDetails = async () => {
    try {
      setLoading(true);

      const res = await fetchPPVDetails(params?.id);

      setPpvData(res.ppv);

      setPurchaseCount(res.purchaseCount);

      setSubscription(res.subscription);
      setIsPaid(res._isPaid);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  // Payment Success For Apple Pay
  const _paymentSuccess = () => {
    setIsVideoOpen(true);
    _fetchPPVDetails();

    setTimeout(() => {
      dispatch(hideLoader());
    }, 500);
  };

  const _onPaymentConfirmation = async (amountToPay, card) => {
    dispatch(showLoader("Payment Processing"));

    const newPaymentApiData = { ...paymentApiData };

    if (card) {
      newPaymentApiData.payload.card = card;
    }

    newPaymentApiData.influencer = ppvData?._influencer;

    try {
      await ProcessPayment.startPaymentProcess(newPaymentApiData, amountToPay);
      _paymentSuccess();
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _checkVideoDuration = (event) => {
    try {
      if (event) event.persist();

      if (!viewCountApiCall) {
        setViewCountApiCall(true);
        increaseViewCount(ppvData?._id);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await createPaymentIntent(
          paymentApiData?.url3DSecure,
          paymentApiData?.payload
        );

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _startStopPPVPreviewVideo = (isPlayVideoPreview = false) => {
    if (ppvData?.previewVideoUrl || ppvData?.previewVideoUrlHlsLink) {
      setIsPlayVideoPreview(isPlayVideoPreview);
    }
  };

  const _onChangeVisibility = (isVisible) => {
    if (previewVideoTimerRef?.current)
      clearTimeout(previewVideoTimerRef.current);
    if (ppvData?.previewVideoUrl || ppvData?.previewVideoUrlHlsLink) {
      if (isVisible) {
        previewVideoTimerRef.current = setTimeout(() => {
          _startStopPPVPreviewVideo(true);
        }, 4000);
      } else {
        _startStopPPVPreviewVideo(false);
      }
    }
  };

  useEffect(() => {
    const newPaymentApiData = { ...paymentApiData };
    newPaymentApiData.payload.ppvId = params?.id;
    setPaymentApiData(newPaymentApiData);

    _getAndUpdateAllCreatorBundles();

    _fetchPPVDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="justify-content-center noMargin mb-3">
            <Col sm={12} md={11} lg={10} className="noPadding">
              <div className="pgTitle justify-content-start customMarginMob">
                {/* back btn */}
                <Button
                  className="customBackBtn"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h2>
                  <ConfigureString keyString={"ppv Details"} />
                </h2>
                {loading ? <CustomLoader className="mx-2" /> : null}
              </div>

              {ppvData && (
                <div className="ppvPreviewStatsWrap">
                  <div className="mediaVideoWrap-Feed">
                    {isVideoOpen ? (
                      ppvData?.contentType === "audio" ? (
                        <AudioPlayer url={ppvData?.videoUrl} autoPlay />
                      ) : (
                        <>
                          {/* custom video player support hls */}
                          <CustomVideoPlayer
                            // className={`${getAspectRatioClass(
                            //   ppvData?.aspectRatio
                            // )}`}
                            hlsLink={ppvData?.hls}
                            thumbnail={ppvData?.coverImage}
                            feedId={ppvData?._id}
                            duration={ppvData?.duration}
                            pictureInPictureMode
                            fullScreenMode
                            autoPlay
                            controls
                            onPlay={(e) => _checkVideoDuration(e)}
                          />
                        </>
                      )
                    ) : null}

                    {!isVideoOpen ? (
                      <div>
                        <VisibilitySensor
                          onChange={(isVisible) =>
                            _onChangeVisibility(isVisible)
                          }
                        >
                          {(ppvData?.previewVideoUrl ||
                            ppvData?.previewVideoUrlHlsLink) &&
                          isPlayVideoPreview ? (
                            <>
                              {/* ppv preview video */}
                              {/* custom video player support hls */}
                              <CustomVideoPlayer
                                // className={`${getAspectRatioClass(
                                //   ppvData?.previewVideoUrlAspectRatio
                                // )}`}
                                onEnded={() => _startStopPPVPreviewVideo()}
                                src={ppvData?.previewVideoUrl}
                                hlsLink={ppvData?.previewVideoUrlHlsLink}
                                duration={ppvData?.previewVideoUrlDuration}
                                autoPlay
                                muted
                              />
                              <div className="videoPreviewTag">
                                Video Preview
                              </div>
                            </>
                          ) : (
                            // PPV Cover Image
                            <>
                              <div className="shadowOverlayImg" />
                              <img
                                className="mediaPhoto-Feed"
                                src={
                                  ppvData?.coverImage || DEFAULT_COVER_PICTURE
                                }
                                alt="PPV Cover Img"
                                loading="lazy"
                              />
                            </>
                          )}
                        </VisibilitySensor>

                        {!ppvData?.isPublic && !isPaid ? (
                          <SvgIcons type="lock" className="lockIconPPV" />
                        ) : (
                          <Button
                            className="videoPlayBtn"
                            onClick={() => setIsVideoOpen(true)}
                          >
                            <i className="fa fa-play" />
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="ppvDetailsWrap">
                    <h5 className="ppvVideoName">{ppvData?.title}</h5>

                    <div className="purchaseNViewsWrap">
                      {purchaseCount ? (
                        <div className="mr-auto">
                          <span>Purchases</span>
                          <p>{purchaseCount || 0}</p>
                        </div>
                      ) : null}

                      {ppvData?.contentType !== "audio" ? (
                        <div>
                          <span>Views</span>
                          <p>{ppvData?.viewCount || 0}</p>
                        </div>
                      ) : null}
                    </div>

                    {ppvData?.description?.length ? (
                      <ReadMoreReadLess
                        className="readMore"
                        text={ppvData.description}
                      />
                    ) : null}
                  </div>

                  {!isPaid && ppvData?.hasOwnProperty("price") ? (
                    <ErrorBoundary>
                      <PaymentTierComponent
                        apiData={paymentApiData}
                        paymentFor={ppvData}
                        subscription={subscription}
                        bundles={feedData?.bundles}
                        influencer={ppvData?._influencer}
                        onPaymentSuccess={_fetchPPVDetails}
                        onBuy={(amountToPay, card) =>
                          _onPaymentConfirmation(amountToPay, card)
                        }
                        // For Apple Pay
                        content={{
                          name: "PayPerView",
                          price:
                            subscription?.tier === "plus"
                              ? ppvData?.plusPrice
                              : subscription?.tier === "premium"
                              ? ppvData?.premiumPrice
                              : ppvData?.price,
                        }}
                        createPaymentIntent={() => _createPaymentIntent()}
                        onApplePaymentSuccess={() => _paymentSuccess()}
                      />
                    </ErrorBoundary>
                  ) : null}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PayPerViewDetailsPage;
