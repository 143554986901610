import * as _deepClone from "clone-deep";
import toast from "react-hot-toast";
import { cloudinaryImageUpload, removeDeviceOnLogout } from "../http-calls";
import moment from "moment";
import * as jwt_decode from "jwt-decode";
import S3BucketUploader from "../s3-bucket-uploader";
import {
  BASE_URL,
  AWS_IMAGE_BUCKET_NAME,
  AWS_VIDEO_BUCKET_NAME,
  AWS_AUDIO_BUCKET_NAME,
  FRONTEND_FAN_BASE_URL,
  DEFAULT_COVER_PICTURE,
  CURRENCY_TYPE,
} from "../config";
import { getToken } from "../interceptors/token-interceptor";
import { currencyCode } from "../config/currencyCode";
import ReactHtmlParser from "react-html-parser";
import { store as REDUX_STORE } from "../redux/store";
import React from "react";
import { RegexConfig } from "../config/RegexConfig";
import { UploadQueueManager } from "../upload-queue-manager";
import { PostManager } from "../post-manager";
import {
  clearAllFeedData,
  clearAllFilters,
  clearAllMessageData,
  clearAllPaymentData,
  clearAllStories,
  clearNotifications,
  clearSearchResult,
  clearUserData,
  clearUserSubscriptions,
  deleteNameConfigurable,
  hideLoader,
  showLoader,
} from "../redux/actions";
import { countryCodes } from "../config/country-codes";
import { getConfiguredString } from "./configureString";
import { MonthsWithDays } from "../config/months-config";
import DeviceMetaData from "../device-metadata";
import OneSignalHelper from "../one-signal/onesignalHelper";

export const logout = async (navRef = null, isDeviceLogoutRequired = false) => {
  try {
    REDUX_STORE.dispatch(showLoader("Logging out your account..."));

    if (isDeviceLogoutRequired) {
      try {
        await removeDeviceOnLogout();
      } catch (error) {
        console.log({ error });
      }
    }

    REDUX_STORE.dispatch(clearUserData());
    REDUX_STORE.dispatch(clearAllMessageData());
    REDUX_STORE.dispatch(clearAllPaymentData());
    REDUX_STORE.dispatch(clearAllFeedData());
    REDUX_STORE.dispatch(clearNotifications());
    REDUX_STORE.dispatch(deleteNameConfigurable());
    REDUX_STORE.dispatch(clearAllFilters());
    REDUX_STORE.dispatch(clearSearchResult());
    REDUX_STORE.dispatch(clearUserSubscriptions());
    REDUX_STORE.dispatch(clearAllStories());
    REDUX_STORE.dispatch(hideLoader());

    if (navRef) navRef.replace("/login");
    // else window.location.reload();
  } catch (error) {
    console.log({ error });
    REDUX_STORE.dispatch(hideLoader());
  }
};

/**
 *
 * @param {Object} object - {tier: String, influencerId: String}
 * @param {Object} object.tier - String
 * @param {Object} object.influencerId - String
 * @returns - boolean - is Subscription price exist or not
 */
export const isSubscriptionPriceExist = ({ tier = "", influencerId = "" }) => {
  try {
    const state = REDUX_STORE.getState();

    const allInfluencerBundles = state?.feedData?.bundles;

    if (!tier || !allInfluencerBundles?.length || !influencerId) return false;

    const bundles = allInfluencerBundles.filter(
      (each) => each._influencer === influencerId
    );

    switch (tier) {
      case "plus": {
        const plusBundle = bundles.find((each) => each?.tier === "plus");
        const isPlusValid =
          plusBundle?.price ||
          plusBundle?.threeMonthsPrice ||
          plusBundle?.sixMonthsPrice ||
          plusBundle?.nineMonthsPrice ||
          plusBundle?.twelveMonthsPrice;
        return isPlusValid ? true : false;
      }
      case "premium": {
        const premiumBundle = bundles.find((each) => each?.tier === "premium");
        const isPremiumValid =
          premiumBundle?.price ||
          premiumBundle?.threeMonthsPrice ||
          premiumBundle?.sixMonthsPrice ||
          premiumBundle?.nineMonthsPrice ||
          premiumBundle?.twelveMonthsPrice;
        return isPremiumValid ? true : false;
      }
      case "basic": {
        const basicBundle = bundles.find((each) => each?.tier === "basic");
        const isBasicValid =
          basicBundle?.price ||
          basicBundle?.threeMonthsPrice ||
          basicBundle?.sixMonthsPrice ||
          basicBundle?.nineMonthsPrice ||
          basicBundle?.twelveMonthsPrice;
        return isBasicValid ? true : false;
      }
      default: {
        return false;
      }
    }
  } catch (error) {
    console.log("error>>", error);
    return false;
  }
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    queryParamString = decodeURIComponent(queryParamString);
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const showToast = (message, type = "error", duration = 4000) => {
  toast.dismiss();

  toast[type](message, { duration });
};

export const onUploadProgress = (evt, onProgressCallback) => {
  let uploadPercentage = parseInt((evt.loaded * 100) / evt.total);
  onProgressCallback(uploadPercentage, evt.key);
};

export const onComplete = (error, success) => {
  console.log("error, success :", error, success);
};

/**
 * To communicate through events
 */
export const EventEmitter = {
  events: {},
  dispatch: function (event, data = null) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Doesn't exist, so just return
      return;
    } else {
      // Exists
      // Process all bound callbacks
      this.events[event].forEach((callback) => callback(data));

      // reset event
      // this.events[event] = [];
    }
  },
  subscribe: function (event, callback) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Not subscribed yet, so make it an array so that further callbacks can be pushed
      this.events[event] = [];
    }
    // Push the current callback
    this.events[event].push(callback);
  },
  unsubscribe: function (event) {
    // reset event
    this.events[event] = [];
  },
};

export const onFilePercentageChange = (callback) => {
  EventEmitter.subscribe("upload-file-percentage-change", callback);
};

export const uploadPhotoToCloudinary = (
  photo,
  type = "blob",
  fileName = null,
  fileType = "image",
  isLargeFile = false,
  onProgressCallback = (uploadPercentage, key) => {
    EventEmitter.dispatch("upload-file-percentage-change", {
      percentage: uploadPercentage,
      key,
    });
  },
  isFakeCall = false
) => {
  return new Promise(async (resolve, reject) => {
    if (isFakeCall) {
      resolve("");
    } else {
      if (type === "blob") {
        const extension =
          fileType === "image"
            ? ".jpg"
            : fileType === "audio"
            ? ".mp3"
            : fileType === "doc"
            ? photo.name
            : ".mp4";

        fileName = `${new Date().toISOString()}_${
          photo.name ? photo.name : extension
        }`;

        // const formData = new FormData();
        if (
          fileType === "video" ||
          fileType === "image" ||
          fileType === "audio" ||
          fileType === "doc"
        ) {
          // formData.append("mediafile", photo, fileName);
          const token = await getToken();

          try {
            const config = await S3BucketUploader.getCreds(
              BASE_URL + "/awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              photo,
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType
            );
            resolve(s3Response.Location);
          } catch (error) {
            reject(error);
          }
        } else {
          const formData = new FormData();
          formData.append("file", photo, fileName);
          cloudinaryImageUpload(
            formData,
            fileType !== "audio" ? fileType : "video",
            isLargeFile
          )
            .then((cloudinaryResponse) => {
              resolve(cloudinaryResponse.secure_url);
            })
            .catch((err) => {
              reject(err);
            });
        }
      } else {
        // Check if filename provided
        if (!fileName || !fileName.length) {
          // Not provided, so generate random one
          fileName =
            Math.random().toString(36).substring(2) + fileType === "image"
              ? ".jpg"
              : fileType === "audio"
              ? ".mp3"
              : ".mp4";
        }
        // this.convertb64Image(photo, fileName);
        if (fileType === "video") {
          const token = await getToken();

          try {
            const config = await S3BucketUploader.getCreds(
              BASE_URL + "/awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              convertb64Image(photo, fileName),
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType
            );

            resolve(s3Response);
          } catch (error) {
            reject(error);
          }
        } else {
          cloudinaryImageUpload(convertb64Image(photo, fileName)).then(
            (cloudinaryResponse) => {
              resolve(cloudinaryResponse.secure_url);
            }
          );
        }
      }
    }
  });
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const convertb64Image = (ImageURL, fileName) => {
  // Split the base64 string in data and contentType
  let block = ImageURL.split(";");
  // Get the content type
  let contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

  // Convert to blob
  let blob = b64toBlob(realData, contentType);

  // Create a FormData and append the file
  let fd = new FormData();
  fd.append("file", blob, fileName);
  return fd;
};

export const transformArrayToObject = (sourceArray, objectKeyPropertyName) => {
  let resultantObject = {};
  if (sourceArray && sourceArray.length) {
    sourceArray.forEach((elem) => {
      resultantObject[elem[objectKeyPropertyName]] = elem;
    });
    return resultantObject;
  }
};

export const isObjectEmpty = (sourceObject = {}) => {
  return Object.keys(sourceObject).length === 0;
};

export const formatDate = (date, isHideSameYear = false) => {
  if (!date) return "";

  if (isHideSameYear && moment().isSame(date, "year")) {
    return moment(date).format("MMM DD");
  } else {
    return moment(date).format("MMM DD, YYYY");
  }
};

export const getPostedTimeValue = (postedDate) => {
  return moment(postedDate).format("HH:mm");
};

export const formatTimeIn12hFormat = (postedDate) => {
  return moment(postedDate).format("hh:mm A");
};

export const formatTime = (postedDate) => {
  return moment(postedDate).format("hh:mm A");
};

export const decodeToken = (token) => {
  return jwt_decode(token);
};

export const fetchAndDecodeToken = () => {
  let state = REDUX_STORE.getState();
  if (state && state.userData && state.userData.token) {
    return decodeToken(state.userData.token);
  }
};

export const sortedThreads = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t2[attr]) - new Date(t1[attr]);
  });
};

export const capitalize = (s) => {
  if (!s || typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeEveryFirstLetter = (text = "") => {
  const modifiedText = text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
  return modifiedText;
};

export const getAWSBucketName = (type) => {
  switch (type) {
    case "image":
      return AWS_IMAGE_BUCKET_NAME;
    case "video":
      return AWS_VIDEO_BUCKET_NAME;
    case "audio":
      return AWS_AUDIO_BUCKET_NAME;
    default:
      return null;
  }
};

export const getResolution = () => {
  const width = window.screen.width;
  if (width < 576) {
    return "360";
  } else if (width >= 576 && width < 768) {
    return "480";
  } else if (width >= 768 && width < 1200) {
    return "720";
  }
  return "1080";

  // if (width < 1200) {
  //   return "720";
  // } else return "1080";

  // set high resolution for any screen size
  // return "1080";
};

export const getTranscodedLink = (link = "", type = "image") => {
  if (!link.length) {
    return;
  }
  let bucketName = getAWSBucketName(type);

  let splitBucketName = bucketName.split("-");

  switch (type) {
    case "image": {
      let splittedUrls = link.split(bucketName);

      if (!splittedUrls.length || splittedUrls.length === 1) {
        return link;
      }

      let splittedSecondParts = splittedUrls[1].split("/");

      splittedSecondParts[splittedSecondParts.length - 1] =
        getResolution() +
        "w_" +
        splittedSecondParts[splittedSecondParts.length - 1];

      splittedUrls[1] = splittedSecondParts.join("/");
      // bucketName = bucketName + "-transcoding";

      bucketName =
        splitBucketName[0] +
        "-compressed-" +
        splitBucketName[1] +
        "-" +
        splitBucketName[2];

      let transcodedLink = splittedUrls.join(bucketName);

      return transcodedLink;
    }

    case "video": {
      let splittedUrls = link.split(bucketName);

      if (!splittedUrls.length || splittedUrls.length === 1) {
        return link;
      }

      let splittedSecondParts = splittedUrls[1].split("/");

      let videoId = splittedSecondParts[splittedSecondParts.length - 1];

      splittedSecondParts[splittedSecondParts.length - 1] =
        videoId + "/Videos/" + videoId + "_" + getResolution() + "p.mp4";

      splittedUrls[1] = splittedSecondParts.join("/");
      // bucketName = bucketName + "-transcoding";

      bucketName =
        splitBucketName[0] +
        "-compressed-" +
        splitBucketName[1] +
        "-" +
        splitBucketName[2];

      let transcodedLink = splittedUrls.join(bucketName);

      return transcodedLink;
    }

    default:
      break;
  }
};

export const getThumbnailLink = (link, type = "video") => {
  if (!link.length) {
    return;
  }
  let bucketName = getAWSBucketName(type);

  let splitBucketName = bucketName.split("-");

  let splittedUrls = link.split(bucketName);

  if (!splittedUrls.length || splittedUrls.length === 1) {
    return link;
  }

  let splittedSecondParts = splittedUrls[1].split("/");

  let videoId = splittedSecondParts[splittedSecondParts.length - 1];

  splittedSecondParts[splittedSecondParts.length - 1] =
    videoId + "/Thumbnails/" + videoId + ".0000000.jpg";

  splittedUrls[1] = splittedSecondParts.join("/");

  bucketName =
    splitBucketName[0] +
    "-compressed-" +
    splitBucketName[1] +
    "-" +
    splitBucketName[2];

  let thumbnailLink = splittedUrls.join(bucketName);

  return thumbnailLink;
};

export const checkPastEvent = (date) => {
  if (moment(date).add(2, "h") > moment()) {
    return false;
  } else return true;
};

export const getSocialLink = (arr, name) => {
  const social = arr && arr.filter((item) => item.name === name);
  if (social && social.length) {
    return social[0].accountUrl;
  }
  return "";
};

export const canSubscriberJoin = (event) => {
  if (event?.isPublic || event?._isPaid) {
    return true;
  } else {
    return false;
  }
};

export const isFromSafari = () => {
  if (
    window.navigator.vendor &&
    window.navigator.vendor.indexOf("Apple") > -1 &&
    window.navigator.userAgent &&
    window.navigator.userAgent.indexOf("CriOS") === -1 &&
    window.navigator.userAgent.indexOf("FxiOS") === -1
  ) {
    return true;
  } else return false;
};

export const calculateContentType = (contents, type) => {
  if (!contents?.length) return 0;

  const filteredContent = contents.filter(
    (content) => (content.contentType || content.mediaType) === type
  );

  return filteredContent.length;
};

export const calculateContentTypeForPublic = (feed = {}, type = "") => {
  if (!feed) return;

  const mediaCount = {
    image: 0,
    audio: 0,
    video: 0,
    folder: 0,
    poll: 0,
    event: 0,
  };

  if (feed?.category === "poll") {
    mediaCount.poll = 1;
  } else if (feed?.hasOwnProperty("_folder")) {
    mediaCount.folder = feed?._folder?.contentCount || 0;
  } else if (feed?.hasOwnProperty("_event")) {
    mediaCount.event = 1;
  } else {
    if (!feed?._contents?.length) return mediaCount;

    feed?._contents?.forEach((content) => {
      mediaCount[content.contentType || content.mediaType] += 1;
    });
  }

  return mediaCount;
};

export const checkAndRemoveExtraCharecter = (string, char) => {
  return string.includes(char) ? string.split(char)[0] : string;
};

export const openUrlForHumboldtPayment = (url, windowoption, name) => {
  var form = document.createElement("form");
  form.id = "payment";
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", name);

  document.body.appendChild(form);

  //note I am using a post.htm page since I did not want to make double request to the page
  //it might have some Page_Load call which might screw things up.
  window.open("post.html", name, windowoption);
  form.submit();
};

export const getCurrency = (country) => {
  const currency = currencyCode.find(
    (obj) => obj.countryCode === country
  ).currency;

  return currency;
};

export const calculateWeekByTime = (timeStamp) => {
  var todayDate = moment();
  var pastDate = moment(timeStamp);
  var diffDays = todayDate.diff(pastDate, "days");
  const w = Math.floor(diffDays / 7);
  return `${w}w`;
};

export const formatTimeFromNow = (timeStamp) => {
  const formatDayOrWeek = (d) => {
    if (d >= 7) {
      const w = Math.floor(d / 7);
      return `${w}w`;
    }
    return `${d}d`;
  };

  const relativeTime = {
    future: "in %s",
    past: "%s ago",
    s: "Just now",
    ss: "%ss",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: (d) => formatDayOrWeek(d),
    M: (d) => calculateWeekByTime(timeStamp),
    MM: (d) => calculateWeekByTime(timeStamp),
    y: "1y",
    yy: "%dy",
  };

  moment.updateLocale("en", {
    relativeTime,
  });

  return moment(timeStamp).fromNow(true);
};

export const generateCalenderDate = (timeStamp) => {
  let fromNow = moment().isSame(timeStamp, "month") ? "This Month" : "Earlier";

  moment.updateLocale("en", {
    calendar: {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "[This Week]",
      nextDay: "[Tomorrow]",
      nextWeek: "D MMMM YYYY",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    },
  });

  return moment(new Date(timeStamp)).calendar();
};

export const generateCalenderDateForMedia = (timeStamp) => {
  let fromNow = moment().isSame(timeStamp, "month")
    ? "This Month"
    : moment().isSame(timeStamp, "year")
    ? moment(timeStamp).format("MMMM")
    : moment(timeStamp).format("MMMM YYYY");

  moment.updateLocale("en", {
    calendar: {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "[This Week]",
      nextDay: "[Tomorrow]",
      nextWeek: "D MMMM YYYY",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    },
  });

  return moment(new Date(timeStamp)).calendar();
};

export const formatCurrencyValue = (data) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: CURRENCY_TYPE?.toUpperCase(),
  });
  let currency = formatter.format(data);
  if (currency && currency.split(".")[1] === "00") {
    return currency.split(".")[0]; /* $2,500 */
  }
  return currency; /* $2,500.15 */
};

export const getWindowDimensions = () => {
  try {
    const screenWidth =
      window?.innerWidth ||
      document?.documentElement?.clientWidth ||
      document?.body.clientWidth;
    const screenHeight =
      window?.innerHeight ||
      document?.documentElement?.clientHeight ||
      document?.body?.clientHeight;

    return {
      screenWidth,
      screenHeight,
    };
  } catch (error) {
    return {};
  }
};

export const parseForTextField = (text) => {
  try {
    if (!text) return "";

    return text.replace(/\r?<br\/>/g, "\n");
  } catch (error) {
    console.log({ error });
    return "";
  }
};

export const reactHtmlParser = (text = "") => {
  try {
    if (!text) return "";

    let parseText = text ? ReactHtmlParser(text) : "";
    if (parseText) {
      parseText = parseText.map((each) => {
        if (typeof each === "string") {
          return each;
        }
        return "\n";
      });
      parseText = parseText.join(" ");
    }

    // return string text
    return parseText;
  } catch (error) {
    console.log({ error });
    return "";
  }
};

export const mentionUserInputParse = (text = "") => {
  if (text?.trim()?.length) {
    text = parseForTextField(text);
    text = text.split(" ");
    text = React.Children.toArray(
      text.map((each) => {
        if (
          each[0] === "@" &&
          RegexConfig["username"].test(String(each.slice(1)).toLowerCase())
        ) {
          return (
            <>
              <a
                className="tagNamePost"
                href={`${FRONTEND_FAN_BASE_URL}/${each.slice(1)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {each}
              </a>{" "}
            </>
          );
        }

        return `${each} `;
      })
    );
    return text;
  }
  return "";
};

/**
 * used for get status -> feature is active or not
 * @param {string} featureName - vault / ppv / event / shoutout
 * feature status will be checked only for "vault, ppv, event, shoutout"
 * @returns - boolean
 */
export const getFeatureStatus = (featureName) => {
  if (!"vault-ppv-event-shoutout"?.includes(featureName)) {
    return true;
  }

  const { nameConfigurable } = REDUX_STORE.getState();
  return nameConfigurable?.[featureName]?.isActive;
};

export const formatDecimalNumber = (number, decimal = 2) => {
  if (number) {
    number = number.toFixed(decimal);
    if (number && number.split(".")[1] === "00") {
      return number.split(".")[0]; /* 50 */
    }
    return number; /* 50.50 */
  }
  return 0;
};

export const errorHandler = (error) => {
  console.log("error>>", error);
  if (error?.type === "card_error" && !error?.reason) {
    error.reason = error.message;
  }
  showToast(
    error?.reason?.length
      ? error?.reason
      : "Something went wrong, Try again after some time."
  );
};

export const getSrcUrl = (item, imageQuality) => {
  let url = DEFAULT_COVER_PICTURE;
  if (item) {
    if (item.previewBlob) {
      url = item.previewBlob;
    } else if (item._contentId && item._contentId.url) {
      if (imageQuality && item._contentId.url[imageQuality]) {
        url = item._contentId.url[imageQuality];
      } else {
        url = item._contentId.url;
      }
    } else if (item._content && item._content.url) {
      if (imageQuality && item._content.url[imageQuality]) {
        url = item._content.url[imageQuality];
      } else {
        url = item._content.url;
      }
    } else if (item.url) {
      if (imageQuality && item.url[imageQuality]) {
        url = item.url[imageQuality];
      } else {
        url = item.url;
      }
    } else if (item?.raw) {
      if (imageQuality && item[imageQuality]) {
        url = item[imageQuality];
      } else {
        url = item.raw;
      }
    }
  }
  return url;
};

// program to get the file extension
export const getFileExtension = (filename) => {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
};

/**
 * uploadFiles is Object Array;
 * object key is;
 * - uploadData
 * - previewBlob
 * - type
 * - forKeyName (optional) return same value for file matching
 *
 * @param {Array} uploadFiles - file Object Array
 * @returns Array Object; object key is;
 * - name
 * - url
 * - contentType
 * - size
 * - thumbnail
 * - forKeyName (return if provided)
 */
export const uploadFileOnServer = (uploadFiles) => {
  return new Promise((resolve) => {
    console.log("uploadFiles>>", uploadFiles);

    const uploadedFiles = [];

    if (uploadFiles && uploadFiles.length) {
      let postID = PostManager.addMediaFilesCount(uploadFiles.length);

      PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
        if (id.postID === postID) {
          await sleepTime(500);
          PostManager.deletePostID(postID);
          resolve(uploadedFiles);
        } else {
          return;
        }
      });

      uploadFiles.forEach((uploadFile) => {
        let mediaData = {};

        if (!uploadFile["type"]) {
          uploadFile["type"] = uploadFile["uploadData"]["type"].split("/")[0];
        }

        if (uploadFile["type"] === "video") {
          mediaData = {
            blobObject: uploadFile["uploadData"],
            blobURL: uploadFile["previewBlob"],
          };
        } else if (uploadFile["type"] === "image") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: uploadFile["previewBlob"],
          };
        } else if (uploadFile["type"] === "audio") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: { blob: uploadFile["uploadData"] },
          };
        } else if (uploadFile["type"] === "doc") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: uploadFile["uploadData"],
          };
        }

        const uploadId = UploadQueueManager.addMediaToQueue(
          mediaData,
          uploadFile.type
        );

        // Listen for upload complete
        UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
          PostManager.onSingleMediaFileUploadCompleted(postID);
          console.log("mediaResponse", mediaResponse, mediaResponse.fileUrl);
          // Upload complete
          // Get content id from backend
          uploadedFiles.push({
            name: uploadFile.uploadData.name,
            url: mediaResponse.fileUrl,
            contentType: uploadFile.type,
            size: uploadFile.uploadData.size,
            forKeyName: uploadFile.forKeyName,
            thumbnail: mediaResponse.thumbnail,
            extension: uploadFile?.uploadData?.name
              ? getFileExtension(uploadFile.uploadData.name)
              : mediaResponse.data?.mediaType,
          });
        });
      });
    }
  });
};

export const getMessageParticipants = (thread) => {
  if (!thread?._participants) {
    return {};
  }

  const influencer = thread._participants.find(
    (p) => p.userType === "Influencer"
  );
  const fan = thread._participants.find((p) => p.userType === "Fan");

  return { fan, influencer };
};

export const generateCalenderDateForChat = (timeStamp) => {
  if (!timeStamp) return "";

  moment.updateLocale("en", {
    calendar: {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "dddd",
      nextWeek: "D MMMM YYYY",
      sameElse: "D MMMM YYYY",
    },
  });

  return moment(new Date(timeStamp)).calendar();
};

export const parseForShowingMessage = (text) => {
  if (!text?.length) return "";

  return reactHtmlParser(text);
};

export const isShowStartCallButton = (message) => {
  if (!message?.startCallTime) {
    return false;
  }

  const endTime = moment(message.startCallTime).add(
    message.shoutoutDuration || 300,
    "seconds"
  );

  return moment().isBefore(endTime);
};

export const splitPhoneNumber = (phoneNumber) => {
  let temp = [];
  if (phoneNumber?.includes("(") && phoneNumber?.includes(")")) {
    temp = phoneNumber.split(")");
    return { phoneNumber: temp[1] };
  } else {
    return { phoneNumber };
  }
};

export const getDialCode = (code) => {
  if (!code) return { dial_code: "+1" };

  let country = countryCodes.find((countryCode) => {
    return countryCode.code === code;
  });

  return country?.dial_code ? country : { dial_code: "+1" };
};

export const formatFileSize = (size) => {
  if (size) {
    const MB = (+size / 1024) * (1 / 1024);
    if (MB > 500) {
      const GB = +MB / 1024;
      return GB ? `${+formatDecimalNumber(GB) || 0.01} GB` : "N/A";
    } else {
      return MB ? `${+formatDecimalNumber(MB) || 0.01} MB` : "N/A";
    }
  } else {
    return "";
  }
};

export const _dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const generateDates = (month, year) => {
  if (!month) month = moment().format("M");
  if (!year) year = moment().subtract(18, "years").year();

  const dates = [];
  if (moment([year]).isLeapYear() && month === "2") {
    for (let i = 1; i <= 29; i++) {
      dates.push(i);
    }
    return dates;
  }

  for (let i = 1; i <= MonthsWithDays[+month]; i++) {
    dates.push(i);
  }
  return dates;
};

export const birthYearWithMinimumAge = (leastAge) => {
  const years = [];
  let currentYear = new Date().getFullYear();
  let start = currentYear - leastAge;

  for (let i = start; years.length + leastAge < 100; i--) {
    years.push(i);
  }
  return years;
};

export const getPaymentTypeInFormat = (
  paymentType,
  forAll = false,
  history
) => {
  switch (paymentType) {
    case "payperview": {
      return getConfiguredString("ppv");
    }
    case "liveEvent": {
      return getConfiguredString("event");
    }
    case "shoutout": {
      return getConfiguredString("shoutout");
    }
    case "vault": {
      return getConfiguredString("vault");
    }
    case "tips": {
      return history?._to?.settings?.tipText?.length
        ? capitalizeEveryFirstLetter(history._to.settings.tipText)
        : "Tips";
    }
    default: {
      if (forAll) {
        return capitalize(paymentType);
      }
      return "";
    }
  }
};

export const formatPaymentTitle = (history) => {
  let title = "N/A";
  if (history?.paymentType) {
    switch (history?.paymentType) {
      case "tips": {
        title = "You tipped";
        break;
      }
      case "payperview": {
        title = `You unlocked ${getConfiguredString("ppv")}`;
        break;
      }
      case "liveEvent": {
        title = `You unlocked ${getConfiguredString("event")}`;
        break;
      }
      case "subscription": {
        title = "You subscribed to";
        break;
      }
      case "shoutout": {
        title = `You paid for ${getConfiguredString("shoutout")} to`;
        break;
      }
      case "vault": {
        title = `You unlocked ${getConfiguredString("vault")}`;
        break;
      }
      case "post": {
        title = "You unlocked Post";
        break;
      }
      case "message": {
        title = "You paid for Message to";
        break;
      }
      default: {
        title = "N/A";
      }
    }
  }
  return title;
};

export const splitFullName = (fullName = "") => {
  if (!fullName || typeof fullName !== "string") return {};

  const splitName = fullName.trim().split(" ");

  return {
    firstName: splitName[0],
    lastName: splitName?.slice(1)?.join(" ").trim() || "",
  };
};

export const openSocialLink = (link = "") => {
  if (!link) return;

  if (link.indexOf("http://") === -1 && link.indexOf("https://") === -1) {
    link = "http://" + link;
  }

  window.open(link, "_blank");
};

export const getDeviceDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const deviceDetails = {};
      deviceDetails["name"] =
        DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();

      // const ipDataRes = await getIpData();

      // deviceDetails["ipAddress"] = ipDataRes["ip"];
      // deviceDetails["ipCountry"] = ipDataRes["country_name"];
      deviceDetails["ipAddress"] = "0.0.0.0";
      deviceDetails["ipCountry"] = "United States";

      // Get device id
      // First try to get from one signal
      let onesignalDeviceId = null;
      onesignalDeviceId = await OneSignalHelper.getDeviceId();
      if (onesignalDeviceId) {
        deviceDetails["allowNotification"] = true;
        deviceDetails["deviceId"] = onesignalDeviceId;
      } else {
        deviceDetails["allowNotification"] = false;
        deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
      }
      resolve(deviceDetails);
    } catch (error) {
      errorHandler({
        reason:
          "Please use a supported browser like Google Chrome, Safari, Internet Explore / Edge",
      });
      reject(error);
    }
  });
};

export const totalCommentsCount = (comments = []) => {
  try {
    if (!comments?.length) return 0;

    let count = comments.length;

    comments.forEach((each) => {
      if (each?._subComments) {
        count += each._subComments;
      }
    });

    return count || 0;
  } catch (error) {
    return 0;
  }
};

// always resolve
export const getOneSignalDeviceId = async () => {
  return new Promise(async (resolve) => {
    try {
      const onesignalDeviceId = await OneSignalHelper.getDeviceId();
      resolve(onesignalDeviceId || null);
    } catch (error) {
      resolve(null);
    }
  });
};

export const isValidPrice = (value) => {
  if (
    isNaN(value) ||
    +value < 0 ||
    (value.includes(".") && value.split(".")[1]?.length > 2)
  )
    return false;

  return true;
};

export const isValidPhone = (value) => {
  if (isNaN(value) || +value < 0 || value.includes(".")) return false;

  return true;
};

export const getDialCodeFromCountryCode = (country_code) => {
  const dialCode =
    countryCodes.find((obj) => obj.code === country_code)?.dial_code || "+1";

  return dialCode;
};

export const getLikeIconClassName = (feed) => {
  if (feed?._isLiked) {
    // in like state
    return "fa fa-heart";
  }

  //in dislike state
  return "fa fa-heart-o";
};

export const getCommentIconClassName = (feed) => {
  if (feed?.comments) {
    //  comment state
    return "fa fa-comment";
  }

  //  no comment state
  return "fa fa-comment-o";
};

export const getSuggestedResolution = () => {
  try {
    const { screenWidth, screenHeight } = getWindowDimensions();

    // Adjust the video resolution based on screen size
    if (screenWidth < 640 || screenHeight < 480) {
      // Set low resolution
      return "threeTwoZero";
    } else if (screenWidth < 1280 || screenHeight < 720) {
      // Set medium resolution
      return "fiveFourZero";
    } else {
      // Set high resolution
      return "oneZeroEightZero";
    }
  } catch (error) {
    return "oneZeroEightZero";
  }
};

export const getAspectRatioClass = (ratio = 0) => {
  if (ratio === 1) {
    // ratio = 1
    return "squareVideoWrap";
  } else if (ratio > 1 || !ratio) {
    // ratio > 1
    return "landscapeVideoWrap"; // default
  } else {
    // ratio < 1
    return "portraitVideoWrap";
  }
};

export const getUrlParams = (url = window.location.href) => {
  try {
    let urlStr = url.split("?")[1];
    return new URLSearchParams(urlStr);
  } catch (error) {
    return null;
  }
};

export const checkUndefinedAndNull = (value) => {
  return value !== undefined && value !== null ? true : false;
};

export const isValueUndefinedAndNull = (value) => {
  return value === undefined || value === null ? true : false;
};

export const convertHexToRgbColor = (hexColor) => {
  try {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5), 16);

    return { r, g, b };
  } catch (error) {
    console.log({ error });
    return {};
  }
};

const twoDigitNumber = (num) => {
  return `${num < 10 ? `0${num}` : num}`;
};

export const convertSecondsToHourMinSec = (seconds) => {
  if (!seconds) return "";

  let sec = Math.floor(seconds);

  if (sec < 60) {
    return `00:${twoDigitNumber(sec)}`;
  } else {
    let min = Math.floor(sec / 60);
    sec = sec % 60;
    if (min < 60) {
      return `${twoDigitNumber(min)}:${twoDigitNumber(sec)}`;
    } else {
      let hour = Math.floor(min / 60);
      min = min % 60;
      if (hour < 24) {
        return `${twoDigitNumber(hour)}:${twoDigitNumber(min)}:${twoDigitNumber(
          sec
        )}`;
      } else {
        return "";
      }
    }
  }
};

export const getLowResolutionLink = (link = "") => {
  return link
    ?.replace("1080", "360")
    ?.replace("720", "360")
    ?.replace("540", "360");
};

export const setupDescription = (introduction = "") => {
  try {
    let descriptionTag = document.querySelector("meta[name~='description']");

    descriptionTag.content =
      introduction || "Join my channel to access exclusive content";
  } catch (error) {
    console.log({ error });
  }
};

export const formatNumberInShort = (num, digits = 1) => {
  if (!num && num !== 0) return;

  const lookup = [
    { value: 1e27, symbol: "Oc" }, // Octillion
    { value: 1e24, symbol: "Sp" }, // Septillion
    { value: 1e21, symbol: "Sx" }, // Sextillion
    { value: 1e18, symbol: "Qi" }, // Quintillion (with zero)
    { value: 1e15, symbol: "Q" }, // Quadrillion
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" },
  ];
  var item = lookup.find(function (item) {
    return +num >= item.value;
  });
  if (item) {
    num = (+num / item.value).toFixed(digits);
    if (num && +num.split(".")[1] > 0) {
      return `${num}${item.symbol}`;
    }
    return `${num.split(".")[0]}${item.symbol}`;
  }
  return "0";
};

export function handleBrokenImageURL() {
  let timer = null,
    count = 0;

  return ({ event, url, fallbackImg }) => {
    if (timer) clearTimeout(timer);

    if (!url) {
      event.target.src = fallbackImg || "";
      return;
    }

    if (count > 20) {
      event.target.src = fallbackImg || "";
      return;
    }

    timer = setTimeout(() => {
      event.target.src = url;
      count++;
    }, 2000);
  };
}

export const copyToClipboard = (text) => {
  try {
    navigator.clipboard.writeText(text);
    showToast("Copied", "success");
  } catch (error) {
    console.log({ error });
  }
};

export const copyPostPublicLink = ({
  influencerUsername = null,
  postId = null,
}) => {
  if (!postId || !influencerUsername) {
    errorHandler();
    return;
  }

  copyToClipboard(
    `${FRONTEND_FAN_BASE_URL}/public/${influencerUsername}/${postId}`
  );
};

export const handleImageQuality = (url, resolution) => {
  if (!url || !resolution) return;

  let actualUrl = url;

  if (resolution === "Original") {
    return actualUrl?.raw;
  }

  switch (resolution) {
    case "360": {
      return actualUrl?.threeSixZero;
    }

    case "480": {
      return actualUrl?.fourEightZero;
    }

    case "720": {
      return actualUrl?.sevenTwoZero;
    }

    case "1080": {
      return actualUrl?.oneZeroEightZero;
    }

    default:
      return actualUrl?.raw;
  }
};

export const containsRestrictedWords = (str = "") => {
  if (typeof str !== "string" || !str) return false;

  const RESTRICTED_WORDS = ["venmo", "cashapp", "paypal", "zelle", "stripe"];

  const lowerCaseStr = str.toLowerCase();

  return RESTRICTED_WORDS.some((word) =>
    lowerCaseStr.includes(word.toLowerCase())
  );
};

export const removeLastWord = (str = "") => {
  if (typeof str !== "string" || !str) return "";

  // Split the string into an array of words
  const words = str.split(" ");

  // Remove the last word from the array
  words.pop();

  // Join the remaining words back into a string
  return words.join(" ");
};

export const getFeedImageSrc = ({ feed, isPublic, isLocked, imageQuality }) => {
  if (isPublic || (!isPublic && !isLocked)) {
    if (feed?._contents?.[0]?._content?.url?.[imageQuality]) {
      return feed?._contents?.[0]?._content?.url?.[imageQuality];
      // return `${feed?._contents?.[0]?._content?.url?.[imageQuality]}?cacheId=${feed?._contents?.[0]?._content?._id}`;
    }
    if (feed?._contents?.[0]?._content?.url) {
      return feed?._contents?.[0]?._content?.url;
      // return `${feed?._contents?.[0]?._content?.url}?cacheId=${feed?._contents?.[0]?._content?._id}`;
    }
  }

  if (feed?.preview) {
    return feed?.preview;
  }

  if (feed?._contents?.[0]?._content?.thumbnail) {
    return feed?._contents?.[0]?._content?.thumbnail;
  }

  return DEFAULT_COVER_PICTURE;
};
