import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import Header from "../containers/Header";
import { AppHeader } from "@coreui/react";
import ProtectedRoute from "../components/ProtectedRoute";
import MediaQueueViewer from "../containers/MediaQueueViewer";
import { errorHandler } from "../helper-methods";
import { AppFooter } from "@coreui/react";
import Footer from "../containers/Footer";
import SidebarMenu from "../containers/SidebarMenu";
import { fetchAndAddNameConfigurable } from "../redux/actions/nameConfigurable";
import { screenshotDector } from "../screenshot-detector/index";
import ProtectedFeatureRoute from "../components/ProtectedFeatureRoute";
import { connectToSocket, disconnectToSocket, newSocket } from "../socket-io";
import {
  getUnreadMessageCountAction,
  unreadMessageCountUpdate,
} from "../redux/actions/chatData";
import {
  fetchNotificationsAsync,
  incrementUnreadNotificationCount,
} from "../redux/actions/notificationData";
import { createRef } from "react";
import MessagesPage from "./MessagesPage";
import MessageInitiatorPage from "./MessageInitiatorPage";
import VaultsPage from "./VaultsPage";
import VaultDetailsPage from "./VaultDetailsPage";
import PayPerViewPage from "./PayPerViewPage";
import PayPerViewDetailsPage from "./PayPerViewDetailsPage";
import LiveEventPage from "./LiveEventPage";
// import CoursesPage from "./CoursesPage";
// import CourseDetails from "./CourseDetails";
import FaqPage from "./FaqPage";
import FeedPage from "./FeedPage";
import CardManagementPage from "./CardManagementPage";
import ManageSubscriptions from "./ManageSubscriptions";
import SearchInfluencers from "./SearchInfluencers";
import InfluencerProfileDetails from "./InfluencerProfileDetails";
import PaymentHistory from "./PaymentHistoryPage";
import PostDetailsPage from "./PostDetailsPage";
import NotificationsPage from "./NotificationsPage";
import SettingsPage from "./SettingsPage";
import LiveEventDetailsPage from "./LiveEventDetailsPage";
import InfluencerPageSelector from "./InfluencerPageSelector";
import LiveEventStreamingPage from "./LiveEventStreamingPage";
import PublicShoutoutDetails from "./PublicShoutoutDetails";
import ScreenshotAlertPage from "./ScreenshotAlertPage";
import { fetchCardsAsync } from "../redux/actions/paymentData";
import CustomPushNotification from "../components/custom/CustomPushNotification";
import { APP_NAME } from "../config";
import MyProfilePage from "./MyProfilePage";
import { getAndUpdateUserData } from "../redux/actions/userData";
import CustomAchievementAlert from "../components/custom/CustomAchievementAlert";

class HomePage extends Component {
  state = {
    isVisibleSidebar: false,
    socket: null,
    notificationData: {
      title: "",
      description: "",
    },
    achievementData: {
      title: "",
      value: "",
      description: "",
    },
  };

  constructor(props) {
    super(props);

    this._componentCleanup = this._componentCleanup.bind(this);

    this.sideBarWrapperRef = createRef();
  }

  _resetNotificationData = () => {
    const { notificationData } = this.state;
    notificationData.title = "";
    notificationData.description = "";
    this.setState({ notificationData });
  };

  _resetAchievementData = () => {
    const { achievementData } = this.state;
    achievementData.title = "";
    achievementData.value = "";
    achievementData.description = "";
    this.setState({ achievementData });
  };

  _listenNotificationAlert = () => {
    try {
      newSocket.on(`newNotification`, (notificationAlert) => {
        console.log("notificationAlert>>", notificationAlert);

        if (notificationAlert.error) {
          errorHandler(notificationAlert);
        } else {
          if (
            this.props.history?.location?.pathname?.includes("/notifications")
          ) {
            this._resetNotificationData();
            this.props.fetchNotifications();
            return;
          }

          if (notificationAlert?.byAdmin) {
            const { notificationData } = this.state;
            notificationData.title = `@${APP_NAME} - Admin`;
            notificationData.description = notificationAlert.title || "";
            this.setState({ notificationData });
          }
          this.props.incrementUnreadNotificationCount();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  _listenNewBadgeEarnAlert = () => {
    try {
      newSocket.on(`newBadgeEarn`, (newBadgeEarnAlert) => {
        console.log("newBadgeEarnAlert>>", newBadgeEarnAlert);

        if (newBadgeEarnAlert.error) {
          errorHandler(newBadgeEarnAlert);
        } else {
          if (this.props.history?.location?.pathname?.includes("/my-profile")) {
            this.props.getAndUpdateUserData();
          }

          const { achievementData } = this.state;
          achievementData.title = newBadgeEarnAlert.title || "";
          achievementData.value = newBadgeEarnAlert.value || "";
          achievementData.description = newBadgeEarnAlert.description || "";
          this.setState({ achievementData });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  _listenUnreadMessageCount = () => {
    try {
      if (this.props.history?.location?.pathname?.includes("/messages")) return;

      let { userData } = this.props;

      newSocket.on("messageNotifiation", (receiveMessage) => {
        console.log("unreadNotificationCount>>", receiveMessage);
        if (receiveMessage.doSkip) return;

        if (receiveMessage.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            this.props.unreadMessageCountUpdate({
              threadId: receiveMessage?._thread,
            });
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _subscribeToSocketRoom = (params) => {
    newSocket.emit("subscribe", params, function (res) {
      console.log("subscribed>>", res);
      if (res.error) {
        errorHandler(res);
      }
    });
  };

  _handleSocketRoomSubscription = () => {
    let { userData } = this.props;

    const roomsToSubscribe = [
      { room: userData?.user?.id },
      { room: `notification/${userData?.user?.id}` },
      { room: `newBadgeEarn/${userData?.user?.id}` },
    ];

    roomsToSubscribe.forEach((roomParams) =>
      this._subscribeToSocketRoom(roomParams)
    );

    this._listenUnreadMessageCount();
    this._listenNotificationAlert();
    this._listenNewBadgeEarnAlert();
  };

  _connectToSocket = () => {
    connectToSocket()
      .then((socket) => {
        if (!this.state.socket?.connected) {
          this.setState({ socket }, () => {
            this._handleSocketRoomSubscription();
          });
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("disconnected>>", flag);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _componentCleanup() {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    this._disconnectToSocket();
  }

  componentDidMount() {
    if (!this.props?.userData?.token) return;

    // fetch and add name configurable - shoutout/vault/ppv/liveEvent
    this.props.fetchAndAddNameConfigurable();

    this.props.fetchNotifications();

    this.props.fetchCards();

    this.props.getUnreadMessageCountAction();

    this._connectToSocket();

    window.addEventListener("beforeunload", this._componentCleanup);
  }

  componentWillUnmount() {
    this._componentCleanup();
    // remove the event handler for normal unmounting
    window.removeEventListener("beforeunload", this._componentCleanup);
  }

  _toggleSideBar = () => {
    if (this.sideBarWrapperRef.current.classList.contains("hideSideMenu")) {
      this.sideBarWrapperRef.current.className = "showSideMenu";
      this.setState({ isVisibleSidebar: true });
    } else {
      this.sideBarWrapperRef.current.className = "hideSideMenu";
      this.setState({ isVisibleSidebar: false });
    }
  };

  render() {
    if (
      process.env.REACT_APP_BACKEND_ENV === "prod" ||
      process.env.REACT_APP_BACKEND_ENV === "dev"
    ) {
      // FOR SCREENSHOT DETECTION
      document.onkeydown = function (event) {
        // c/C => 67 /,/ i/I => 73 /,/ u/U => 85 /,/ p/P => 80 /,/ F12 => 123 //
        const key = event.key ? event.key.toLowerCase() : null;

        if (
          key &&
          (key === "f12" ||
            (event.ctrlKey && (key === "i" || key === "u" || key === "p")))
        ) {
          return false;
        }
        return true;
      };

      let prevKey;

      document.onkeydown = async (event) => {
        // event.preventDefault();

        if (event.key === "Meta") {
          prevKey = event.key;
        }
        if (prevKey && event.key !== "Shift") {
          prevKey = "";
        }
        if (prevKey === "Meta" && event.key === "Shift") {
          this.props.history.push("/screenshot-alert");
          // console.log("qwerty1");
          await screenshotDector(event);
        }
        if (event.metaKey && event.shiftKey) {
          this.props.history.push("/screenshot-alert");
          // console.log("qwerty2");
          await screenshotDector(event);
        }
      };

      document.onkeyup = async (event) => {
        event.preventDefault();

        if (
          event.keyCode === 44 ||
          event.keyCode === 124 ||
          event.keyCode === 42 // For window and Linux
        ) {
          this.props.history.push("/screenshot-alert");
          // console.log("qwerty3");
          await screenshotDector(event);
        }
      };
    }

    const { userData } = this.props;

    const queryParam = `?redirectTo=${window.location.pathname}`;

    return (
      <React.Fragment>
        <div className="app">
          {userData && userData.hasOwnProperty("token") && userData.token ? (
            <AppHeader fixed>
              <Header
                toggleSideBar={() => this._toggleSideBar()}
                isVisibleSidebar={this.state.isVisibleSidebar}
              />
            </AppHeader>
          ) : null}

          <div className="app-body customSidebarWrap">
            {userData && userData.hasOwnProperty("token") && userData.token ? (
              <div
                ref={this.sideBarWrapperRef}
                className={`hideSideMenu`} // showSideMenu
              >
                <div
                  className="sidebarOverlay"
                  onClick={() => this._toggleSideBar()}
                />
                <SidebarMenu
                  toggleSideBar={() => this._toggleSideBar()}
                  isVisibleSidebar={this.state.isVisibleSidebar}
                />
              </div>
            ) : null}

            <main className="main">
              <Container fluid style={{ background: "#fafafa" }}>
                <CustomPushNotification
                  title={this.state?.notificationData?.title}
                  description={this.state?.notificationData?.description}
                  onDismiss={() => this._resetNotificationData()}
                />

                <CustomAchievementAlert
                  title={this.state?.achievementData?.title}
                  value={this.state?.achievementData?.value}
                  description={this.state?.achievementData?.description}
                  onDismiss={() => this._resetAchievementData()}
                />

                <Switch>
                  <ProtectedFeatureRoute
                    exact
                    path="/vault"
                    component={VaultsPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="vault"
                  />
                  <ProtectedFeatureRoute
                    exact
                    path="/vault/folder/:id"
                    component={VaultDetailsPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="vault"
                  />
                  <ProtectedFeatureRoute
                    exact
                    path="/live-events"
                    component={LiveEventPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="event"
                  />
                  <ProtectedFeatureRoute
                    exact
                    path="/live-event-streaming/:id"
                    component={LiveEventStreamingPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="event"
                  />
                  <ProtectedFeatureRoute
                    exact
                    path="/live-events/:id"
                    component={LiveEventDetailsPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="event"
                  />
                  <ProtectedFeatureRoute
                    exact
                    path="/ppv"
                    component={PayPerViewPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="ppv"
                  />
                  <ProtectedFeatureRoute
                    exact
                    path="/ppv/:id"
                    component={PayPerViewDetailsPage}
                    redirectRoute="/feed"
                    queryParam={queryParam}
                    featureName="ppv"
                  />
                  {/* <ProtectedRoute
                    exact
                    path="/courses"
                    component={CoursesPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/course-details/:id"
                    component={CourseDetails}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  /> */}
                  {/* =============== */}
                  <ProtectedRoute
                    exact
                    path="/faq"
                    component={FaqPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  {/* Need to implement component */}
                  <ProtectedRoute
                    exact
                    path="/billing"
                    component={FeedPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/store"
                    component={FeedPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  {/* End here */}

                  <ProtectedRoute
                    exact
                    path="/influencer-selector/:id"
                    component={InfluencerPageSelector}
                    redirectRoute="/:id"
                  />

                  <ProtectedRoute
                    exact
                    path="/influencer/:id/:postId"
                    component={InfluencerProfileDetails}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />

                  <ProtectedRoute
                    exact
                    path="/influencer/:id"
                    component={InfluencerProfileDetails}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />

                  <ProtectedRoute
                    exact
                    path="/initate-message"
                    component={MessageInitiatorPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/feed"
                    component={FeedPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  {/* <ProtectedRoute
                    exact
                    path="/search-creators"
                    component={SearchCreatorPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  /> */}
                  <ProtectedRoute
                    exact
                    path="/billing/manage-cards"
                    component={CardManagementPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/manage-subscriptions"
                    component={ManageSubscriptions}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/billing/payment-history"
                    component={PaymentHistory}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/search"
                    component={SearchInfluencers}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/notifications"
                    component={NotificationsPage}
                  />
                  <ProtectedRoute
                    exact
                    path="/my-profile"
                    component={MyProfilePage}
                  />
                  <ProtectedRoute
                    exact
                    path="/settings"
                    component={SettingsPage}
                  />
                  <ProtectedRoute
                    exact
                    path="/post/:id"
                    component={PostDetailsPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />
                  <ProtectedRoute
                    exact
                    path="/screenshot-alert"
                    component={ScreenshotAlertPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />

                  <ProtectedRoute
                    exact
                    path="/messages"
                    component={MessagesPage}
                    redirectRoute="/login"
                    queryParam={queryParam}
                  />

                  <ProtectedRoute
                    exact
                    path="/:id/:postId"
                    component={InfluencerProfileDetails}
                    redirectRoute="/public/:id/:postId"
                  />

                  <Route
                    exact
                    path="/shoutout/public/:id"
                    component={PublicShoutoutDetails}
                  />

                  <Route
                    exact
                    path="*"
                    render={() => <Redirect to="/feed" />}
                  />
                </Switch>
              </Container>
            </main>
          </div>
          <MediaQueueViewer />

          <AppFooter>
            <Footer
              toggleSideBar={() => this._toggleSideBar()}
              isVisibleSidebar={this.state.isVisibleSidebar}
            />
          </AppFooter>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    unreadMessageCount: state?.chatData?.unreadMessageCount || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: (pagination) =>
      dispatch(fetchNotificationsAsync(pagination)),
    fetchAndAddNameConfigurable: (configurable) =>
      dispatch(fetchAndAddNameConfigurable(configurable)),
    unreadMessageCountUpdate: ({ threadId }) =>
      dispatch(unreadMessageCountUpdate({ threadId })),
    getUnreadMessageCountAction: () => dispatch(getUnreadMessageCountAction()),
    fetchCards: () => dispatch(fetchCardsAsync()),
    incrementUnreadNotificationCount: () =>
      dispatch(incrementUnreadNotificationCount()),
    getAndUpdateUserData: () => dispatch(getAndUpdateUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
