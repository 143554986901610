import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { APP_LOGO, DEFAULT_PROFILE_PICTURE } from "../config";
import { getWindowDimensions } from "../helper-methods";
import { getSuggestedCreatorsAndEvents } from "../http-calls";
import { useDispatch, useSelector } from "react-redux";
import { addSuggestedCreators, addSuggestedEvents } from "../redux/actions";
import { useHistory } from "react-router-dom";

const SuggestedCreatorsCard = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const feedData = useSelector((state) => state?.feedData);

  const [screenWidth, setScreenWidth] = useState(0);

  const _getCarouselMediaForCover = useCallback((influencer) => {
    const newCarousel = {};

    if (!influencer?.carouselMediaForCover?.url) {
      return newCarousel;
    }

    newCarousel.url = influencer?.carouselMediaForCover?.url;

    const carouselCrop = influencer?.carouselCrop;

    if (carouselCrop?.length) {
      const findCropObj = carouselCrop?.find(
        (each) => each.carouselIndex === 0 || each.carouselIndex === 1
      );

      if (findCropObj) {
        newCarousel.crop = findCropObj;
      }
    }

    return newCarousel;
  }, []);

  const _changeWidth = () => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  const _redirectToInfluencerProfilePage = (influencer) => {
    history.push(`/influencer/${influencer?.username}`);
  };

  const _getSuggestedCreatorsAndEvents = async () => {
    try {
      const res = await getSuggestedCreatorsAndEvents();
      dispatch(addSuggestedCreators({ data: res?.influencers || [] }));
      dispatch(addSuggestedEvents({ data: res?.events || [] }));
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    _getSuggestedCreatorsAndEvents();

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!feedData?.suggestedCreators?.length) return <></>;

  return (
    <>
      <Card className="creatorsSuggestionWrap">
        <CardHeader>Recommendations</CardHeader>
        <CardBody>
          <ListGroup>
            {feedData?.suggestedCreators?.slice(0, 5)?.map((each, index) => (
              <ListGroupItem key={`suggestedCreators_${each?._id}_${index}`}>
                <div
                  {...(screenWidth <= 575.98
                    ? {
                        style: {
                          width: screenWidth,
                          height: screenWidth / 3,
                        },
                      }
                    : {})}
                  className="searchResultsNewWrap"
                >
                  <div className="searchResultsNew creatorSuggestions">
                    {_getCarouselMediaForCover(each)?.url ? (
                      <div className="imgCropWrap">
                        <img
                          src={_getCarouselMediaForCover(each)?.url}
                          alt="cover"
                          onError={(e) => (e.target.src = APP_LOGO)}
                          className={
                            "infCoverImg " +
                              _getCarouselMediaForCover(each)?.crop?.height ===
                            100
                              ? "widthAuto"
                              : "heightAuto"
                          }
                          style={{
                            transform: `translate(-${
                              (_getCarouselMediaForCover(each)?.crop?.x ||
                              _getCarouselMediaForCover(each)?.crop?.x === 0
                                ? _getCarouselMediaForCover(each)?.crop?.x
                                : 0) + "%"
                            }, -${
                              (_getCarouselMediaForCover(each)?.crop?.y ||
                              _getCarouselMediaForCover(each)?.crop?.y === 0
                                ? _getCarouselMediaForCover(each)?.crop?.y
                                : 0) + "%"
                            })`,
                          }}
                        />
                      </div>
                    ) : (
                      <div className="defaultCoverImg">
                        <img src={APP_LOGO} alt="cover" />
                      </div>
                    )}
                    <div className="creatorSuggestionsInfo">
                      <img
                        src={each?.profilePicture || DEFAULT_PROFILE_PICTURE}
                        alt="Profile Img"
                        loading="lazy"
                      />
                      <div>
                        {/* <h5>{each?.name?.full}</h5> */}
                        <Button
                          color="link"
                          onClick={() => _redirectToInfluencerProfilePage(each)}
                        >
                          @{each?.username}
                        </Button>
                      </div>
                      <Button
                        onClick={() => _redirectToInfluencerProfilePage(each)}
                      >
                        Follow
                      </Button>
                    </div>
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
        <CardFooter>
          <Button color="link" onClick={() => history.push("/search")}>
            See More
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default SuggestedCreatorsCard;
