import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllStories } from "../../http-calls";

const clearAllStories = createAction("clearAllStories");

const getAndUpdateStoriesAsync = createAsyncThunk(
  "storiesData/getAndUpdateStoriesAsync",
  async (payload, thunkAPI) => {
    const res = await getAllStories(payload);
    console.log(res);
    return {
      stories: res?.stories,
      storiesNonSub: res?.storiesNonSub,
      liveEvent: res?.liveEvent,
      liveEventNonSub: res?.liveEventNonSub,
    };
  }
);

export { clearAllStories, getAndUpdateStoriesAsync };
