import { createSlice } from "@reduxjs/toolkit";
import { clearAllStories, getAndUpdateStoriesAsync } from "../actions";

const initialState = {
  stories: [],
  storiesNonSub: [],
  liveEvent: [],
  liveEventNonSub: [],
  loading: false,
  loadingStoryOfInfluncer: false,
};

const categoriseStoryByInfluencer = (stories) => {
  if (!stories.length) return [];

  const result = stories.reduce((acc, item) => {
    const influencerId = item._createdBy?.id;
    const existingInfluencer = acc.find(
      (influencer) => influencer?._createdBy?.id === influencerId
    );

    if (existingInfluencer) {
      existingInfluencer.stories.push(item);
    } else {
      acc.push({
        _createdBy: item?._createdBy,
        stories: [item],
      });
    }
    return acc;
  }, []);

  return result;
};

const storiesDataSlice = createSlice({
  name: "storiesData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearAllStories, (state, action) => {
        state.stories = [];
      })

      .addCase(getAndUpdateStoriesAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateStoriesAsync?.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateStoriesAsync.fulfilled, (state, payload) => {
        state.stories = categoriseStoryByInfluencer(payload?.payload?.stories);
        state.storiesNonSub = categoriseStoryByInfluencer(
          payload?.payload?.storiesNonSub
        );
        state.liveEvent = payload?.payload?.liveEvent;
        state.liveEventNonSub = payload?.payload?.liveEventNonSub;
        state.loading = false;
      });
  },
});

export const storiesDataReducer = storiesDataSlice.reducer;
