import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonStory = ({ count = 2 }) => {
  return (
    <div className="d-flex align-items-center">
      {[...Array(count)].map((_, index) => (
        <Skeleton
          key={index}
          circle
          height={100}
          width={100}
          className="mr-2"
        />
      ))}
    </div>
  );
};

export default SkeletonStory;
