import React, { Fragment, useMemo } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { countryCodes } from "../config/country-codes";
import { MonthsInEngConfig } from "../config/months-config";
import { BIO_MAX_LIMIT, DEFAULT_PROFILE_PICTURE } from "../config";
import { useEffect, useState } from "react";
import {
  errorHandler,
  logout,
  showToast,
  splitPhoneNumber,
  uploadFileOnServer,
  getDialCode,
  splitFullName,
  generateDates,
  birthYearWithMinimumAge,
  getDialCodeFromCountryCode,
  getLowResolutionLink,
  handleBrokenImageURL,
} from "../helper-methods";
import { RegexConfig } from "../config/RegexConfig";
import SocialMediaLinks from "./SocialMediaLinks";
import { useDispatch, useSelector } from "react-redux";
import {
  accountDelete,
  checkDuplicateFields,
  updateFanDetails,
} from "../http-calls";
import ImageCropUploaderModal from "./modals/ImageCropUploaderModal";
import { updateOnlyUserData } from "../redux/actions";
import moment from "moment";
import PromptModal from "./modals/PromptModal";
import { useHistory } from "react-router-dom";
import { MIN_SIGNUP_AGE, badgesConfig } from "../config/helper-config";
import SvgIcons from "./SvgIcons";
import ViewAchievementsModal from "./modals/ViewAchievementsModal";
import CustomTooltip from "./custom/CustomTooltip";

const ProfileSettings = () => {
  const _handleBrokenImageURL = handleBrokenImageURL();

  const history = useHistory();

  const dispatch = useDispatch();

  const fan = useSelector((state) => state?.userData?.user || {});

  const [formFields, setFormFields] = useState({
    name: "",
    username: "",
    email: "",
    phoneCountry: "",
    phone: "",
    gender: "",
    introduction: "",
    occupation: "",
    hobby: "",
    birthDate: "",
    birthMonth: "",
    birthYear: "",
    profilePicture: {
      previewBlob: "",
      uploadData: null,
      uploadUrl: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [isAboutFieldFocus, setIsAboutFieldFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicateCheckLoading, setDuplicateCheckLoading] = useState({
    username: false,
    email: false,
    phone: false,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteAccountLoading, setIsDeleteAccountLoading] = useState(false);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [cropModalContent, setCropModalContent] = useState(false);
  const [isPictureUploading, setIsPictureUploading] = useState(false);

  const [viewAchievementsModal, setViewAchievementsModal] = useState({
    isOpen: false,
  });

  const filteredBadge = useMemo(() => {
    if (fan?.earnBadges?.length) {
      return badgesConfig
        .map((each) => {
          const findBadge = fan?.earnBadges?.find(
            (subEach) => subEach?.name === each.value
          );
          if (findBadge) {
            return { ...each, influencer: [], isLocked: false };
          }
          return {
            ...each,
            influencer: [],
            isLocked: true,
          };
        })
        .sort((a, b) => a.isLocked - b.isLocked);
    }

    return badgesConfig.map((each) => ({
      ...each,
      influencer: [],
      isLocked: true,
    }));
  }, [fan?.earnBadges]);

  const _toggleViewAchievementsModal = (isOpen = false) => {
    setViewAchievementsModal({
      isOpen,
    });
  };

  // create payload to send over api call
  const _createPayload = (formFields) => {
    // Format name
    const { firstName, lastName } = splitFullName(formFields?.name);

    const payload = {
      name: {
        first: firstName?.length ? firstName : "",
        last: lastName?.length ? lastName : "",
        full: firstName + " " + lastName,
      },
      username: formFields?.username?.trim() || "",
      email: formFields?.email?.length ? formFields.email?.trim() : "",
      phoneCountry: formFields?.phoneCountry?.length
        ? formFields.phoneCountry
        : "",
      phone: formFields?.phone?.length
        ? `(${getDialCode(formFields?.phoneCountry).dial_code})` +
          formFields?.phone.toString()?.trim()
        : "",
      introduction: formFields?.introduction?.length
        ? formFields?.introduction?.trim()
        : "",
      occupation: formFields?.occupation?.length
        ? formFields?.occupation?.trim()
        : "",
      hobby: "",
      dob: {
        day: formFields?.birthDate?.length ? formFields?.birthDate : "",
        month: formFields?.birthMonth?.length ? formFields?.birthMonth : "",
        year: formFields?.birthYear?.length ? formFields?.birthYear : "",
      },
    };

    if (formFields?.gender) {
      payload["gender"] = formFields?.gender;
    }

    return payload;
  };

  const _manangeDuplicateLoading = (key = "", value = false) => {
    setDuplicateCheckLoading((prev) => ({ ...prev, [key]: value }));
  };

  const _formFieldsApiValidation = ({ newFormFields, newIsDirty, key }) => {
    return new Promise(async (resolve) => {
      const newErrors = {};
      let isFieldValid = true;

      if (newIsDirty[key]) {
        if (newFormFields[key]?.trim().length) {
          if (
            RegexConfig?.[key].test(String(newFormFields[key]).toLowerCase())
          ) {
            const phoneCountry = getDialCodeFromCountryCode(
              newFormFields?.phoneCountry
            );

            const checkingValue =
              key !== "phone"
                ? newFormFields[key].trim()
                : `(${phoneCountry})${newFormFields[key].trim()}`;

            if (fan[key] === checkingValue) {
              newErrors[key] = null;
              newIsDirty[key] = false;
            } else {
              try {
                const payload = {
                  [key]: checkingValue,
                };
                _manangeDuplicateLoading(key, true);
                const res = await checkDuplicateFields(payload);
                _manangeDuplicateLoading(key, false);

                if (res?.isDuplicateFound) {
                  newErrors[key] = `${newFormFields[
                    key
                  ].trim()} already in use`;
                  isFieldValid = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } catch (error) {
                console.log(error);
                setDuplicateCheckLoading({});
              }
            }
          } else {
            newErrors[key] = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          newErrors[key] = "*Required";
          isFieldValid = false;
        }
      }

      setErrors((prev) => ({
        ...prev,
        [key]: newErrors[key],
      }));

      setIsDirty((prev) => ({
        ...prev,
        [key]: newIsDirty[key],
      }));

      resolve(isFieldValid);
    });
  };

  const _validateFormFields = (newFormFields, newIsDirty) => {
    const newErrors = { ...errors };

    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (!newIsDirty[key]) return;

        switch (key) {
          case "name": {
            if (newFormFields[key]?.trim().length > 0) {
              if (
                RegexConfig.name.test(
                  String(newFormFields[key]).toLowerCase()
                ) &&
                newFormFields[key].length >= 3
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] = "Invalid Name, It must start with a character";
                isFormValid = false;
              }
            } else {
              newErrors[key] = "*Required";
              isFormValid = false;
            }
            break;
          }

          case "username": {
            if (newFormFields[key]?.trim().length > 0) {
              if (
                RegexConfig?.[key]?.test(
                  String(newFormFields[key]).toLowerCase()
                ) &&
                newFormFields[key].length >= 3
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] = "Invalid username";
                isFormValid = false;
              }
            } else {
              newErrors[key] = "*Required";
              isFormValid = false;
            }
            break;
          }

          default:
        }
      });

      setIsDirty(newIsDirty);
      setErrors(newErrors);

      resolve(isFormValid);
    });
  };

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    if (key === "introduction") setIsAboutFieldFocus(false);

    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    if (key === "phone" || key === "username") {
      _formFieldsApiValidation({ newFormFields, newIsDirty, key });
    } else {
      _validateFormFields(newFormFields, newIsDirty);
    }
  };

  const _updateFieldValue = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    ) {
      return;
    }

    let newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onAboutFieldFocus = () => {
    setIsAboutFieldFocus(true);
  };

  const _handleSubmit = async () => {
    try {
      setIsLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const [isFormValid, isPhonevalid, isEmailValid, isUsernameValid] =
        await Promise.all([
          _validateFormFields(newFormFields, newIsDirty),
          _formFieldsApiValidation({
            newFormFields,
            newIsDirty: { phone: true },
            key: "phone",
          }),
          _formFieldsApiValidation({
            newFormFields,
            newIsDirty: { email: true },
            key: "email",
          }),
          _formFieldsApiValidation({
            newFormFields,
            newIsDirty: { username: true },
            key: "username",
          }),
        ]);

      if (!isFormValid || !isPhonevalid || !isEmailValid || !isUsernameValid) {
        setIsLoading(false);
        return errorHandler({ reason: "Fill Details Correctly" });
      }

      const payload = _createPayload(newFormFields);
      const response = await updateFanDetails(payload);
      dispatch(updateOnlyUserData(response?.user));
      showToast("Personal Details Updated", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  const _setFormData = () => {
    let newFormFields = { ...formFields };

    newFormFields["profilePicture"] = {
      previewBlob: "",
      uploadData: null,
      uploadUrl: fan?.profilePicture?.length ? fan?.profilePicture : "",
    };

    newFormFields["name"] = fan?.name?.full?.trim() || "";
    newFormFields["username"] = fan?.username?.trim() || "";
    newFormFields["email"] = fan?.email || "";
    newFormFields["gender"] = fan?.gender || "";

    newFormFields["phone"] = fan?.phone?.length
      ? splitPhoneNumber(fan.phone).phoneNumber
      : "";
    newFormFields["phoneCountry"] = fan?.phoneCountry?.length
      ? fan?.phoneCountry
      : "";

    newFormFields["introduction"] = fan?.introduction ? fan.introduction : "";
    newFormFields["occupation"] = fan?.occupation ? fan.occupation : "";

    // newFormFields["hobby"] = fan?.hobby ? fan.hobby : "";

    // add birthday
    if (fan?.dob) {
      newFormFields["birthDate"] = fan?.dob?.day?.length
        ? fan?.dob?.day
        : moment().format("DD");
      newFormFields["birthMonth"] = fan?.dob?.month?.length
        ? fan?.dob?.month
        : moment().format("M");
      newFormFields["birthYear"] = fan?.dob?.year?.length
        ? fan?.dob?.year
        : moment().subtract(18, "years").year();
    }
    setFormFields(newFormFields);
  };

  const _toggleDeleteModal = (value) => {
    setIsDeleteModalOpen(value);
  };

  const _deleteAccount = async () => {
    try {
      _toggleDeleteModal(false);
      setIsDeleteAccountLoading(true);
      await accountDelete();
      setIsDeleteAccountLoading(false);
      logout(history, true);
      showToast("Your Account has been Deleted", "success");
    } catch (error) {
      setIsDeleteAccountLoading(false);
      errorHandler(error);
    }
  };

  const _toggleCropModal = (value) => {
    setIsCropModalOpen(value);
  };

  // function to handle change in file input
  const _fileChangeHandler = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;
      const fileType = file.type.split("/")?.[0];

      if (fileType !== "image") {
        return errorHandler({ reason: "Please provide a valid image file." });
      }

      const newFormFields = { ...formFields };
      newFormFields["profilePicture"] = {
        previewBlob: URL.createObjectURL(e.target.files[0]),
        uploadData: e.target.files[0],
        uploadUrl: "",
      };

      setFormFields(newFormFields);

      setCropModalContent(file);
      setIsCropModalOpen(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _uploadProfilePicture = async (pictureData) => {
    try {
      setIsPictureUploading(true);

      const serverResponse = await uploadFileOnServer(pictureData);

      const response = await updateFanDetails({
        profilePicture: serverResponse?.[0]?.url,
      });

      // updating redux after http-call so that working profile pic link can be updated to redux
      dispatch(updateOnlyUserData(response.user));
      setIsPictureUploading(false);
      showToast("Profile Picture Updated", "success");
    } catch (error) {
      setIsPictureUploading(false);
      errorHandler(error);
    }
  };

  // reset previewBlob and use url if available
  const _resetPhoto = () => {
    const newFormFields = { ...formFields };

    newFormFields["profilePicture"] = {
      uploadData: null,
      previewBlob: null,
      uploadUrl: fan?.profilePicture?.length ? fan?.profilePicture : "",
    };

    setFormFields(newFormFields);
  };

  const _saveCroppedPhoto = async (croppedImageUrl, croppedImageBlob) => {
    const payload = [
      {
        previewBlob: croppedImageUrl,
        uploadData: croppedImageBlob,
      },
    ];

    try {
      const newFormFields = { ...formFields };
      newFormFields.profilePicture.previewBlob = croppedImageUrl;
      setFormFields(newFormFields);
    } catch (error) {
      console.log({ error });
    }

    setIsCropModalOpen(false);
    _uploadProfilePicture(payload);
  };

  useEffect(() => {
    _setFormData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fan]);

  return (
    <>
      {/* <div className="d-flex flex-column flex-md-row mt-3"> */}
      <div>
        <div className="img120by120 mb-3 mx-auto mt-1">
          <div className="imagePreview">
            <img
              src={
                getLowResolutionLink(formFields?.profilePicture?.uploadUrl) ||
                formFields?.profilePicture?.previewBlob ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) =>
                _handleBrokenImageURL({
                  event: e,
                  url: getLowResolutionLink(fan?.profilePicture),
                  fallbackImg: DEFAULT_PROFILE_PICTURE,
                })
              }
              alt="Profile Img"
              loading="lazy"
            />
          </div>

          <Label className="uploadBtn">
            <input
              type="file"
              style={{ display: "none" }}
              value={""}
              onChange={(e) => _fileChangeHandler(e)}
              disabled={isPictureUploading}
              accept="image/x-png,image/gif,image/jpeg"
            />
            {isPictureUploading ? (
              <i className="fa fa-spinner fa-spin mr-1 uploadIcon" />
            ) : (
              <i className="fa fa-pencil uploadIcon" />
            )}
          </Label>
        </div>

        <div className="achievementsSummary">
          <div>
            {filteredBadge?.slice(0, 5).map((each, index) => (
              <div
                id={`profile_customTooltip_badgesConfig_${each.value}_${index}`}
                key={`profile_customTooltip_badgesConfig_${each.value}_${index}`}
                className={`${!each.isLocked ? "active" : ""}`}
              >
                <SvgIcons type={each?.value} />
                <CustomTooltip
                  text={each.label}
                  target={`profile_customTooltip_badgesConfig_${each.value}_${index}`}
                />
              </div>
            ))}
          </div>

          <Button
            color="link"
            onClick={() => _toggleViewAchievementsModal(true)}
          >
            {badgesConfig?.length} Achievements{" "}
            <i className="fa fa-chevron-right" />
          </Button>
        </div>
      </div>

      <div className="w-100">
        <FormGroup>
          <Label>Username</Label>
          <InputGroup>
            <Input
              type="text"
              placeholder={"Enter Username"}
              value={formFields?.username}
              onChange={(e) => _updateFieldValue("username", e.target.value)}
              onBlur={() => _onBlurHandler("username")}
            />
            <InputGroupAddon addonType="append">
              {duplicateCheckLoading?.username ? (
                <div className="spinnerLogin">
                  <i className="fa fa-spinner fa-spin mr-1" />
                </div>
              ) : null}
            </InputGroupAddon>
          </InputGroup>

          {errors?.username ? (
            <p className="form-error">{errors.username}</p>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            placeholder={"Enter Name"}
            value={formFields?.name}
            onChange={(e) => _updateFieldValue("name", e.target.value)}
            onBlur={() => _onBlurHandler("name")}
          />

          {errors?.name ? <p className="form-error">{errors.name}</p> : null}
        </FormGroup>

        <FormGroup>
          <Label>Email</Label>
          <InputGroup>
            <Input
              type="email"
              placeholder={"Enter Email"}
              autoComplete="Email"
              disabled={true}
              value={formFields.email}
            />
            <InputGroupAddon addonType="append">
              {duplicateCheckLoading?.email ? (
                <div className="spinnerLogin">
                  <i className="fa fa-spinner fa-spin mr-1" />
                </div>
              ) : null}
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <div className="phoneNGenderWrap">
          <FormGroup>
            <Label>Phone</Label>
            <div className="d-flex">
              <Input
                type="select"
                name="selectCountryCode"
                id="selectCountryCode"
                onChange={(e) =>
                  _updateFieldValue("phoneCountry", e.target.value)
                }
                value={formFields?.phoneCountry}
                onBlur={() => _onBlurHandler("countryCode")}
                style={{
                  minWidth: 110,
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                  paddingRight: 20,
                }}
              >
                {countryCodes.map((countryCode, countryIndex) => (
                  <option key={countryIndex} value={countryCode.code}>
                    {`${countryCode.name} ${countryCode.dial_code}`}
                  </option>
                ))}
              </Input>
              <div className="position-relative w-100">
                <Input
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder={"Enter Phone"}
                  autoComplete="nope"
                  value={formFields.phone}
                  onChange={(e) => _updateFieldValue("phone", e.target.value)}
                  onBlur={() => _onBlurHandler("phone")}
                  style={{
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                  }}
                />
                {duplicateCheckLoading?.phone ? (
                  <div className="spinnerLogin">
                    <i className="fa fa-spinner fa-spin mr-1" />
                  </div>
                ) : null}
              </div>
            </div>

            {errors?.phone ? (
              <p className="form-error">{errors.phone}</p>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Gender</Label>

            <div className="d-flex mt-1">
              <CustomInput
                type="radio"
                id="male_custom_radio_btn_profile_setting"
                name="customRadio"
                value="male"
                checked={formFields.gender === "male"}
                onChange={(e) => _updateFieldValue("gender", "male")}
                onBlur={() => _onBlurHandler("gender")}
                className="mr-4"
                label="Male"
              />

              <CustomInput
                type="radio"
                id="female_custom_radio_btn_profile_setting"
                name="customRadio"
                value="female"
                checked={formFields.gender === "female"}
                onChange={(e) => _updateFieldValue("gender", "female")}
                onBlur={() => _onBlurHandler("gender")}
                label="Female"
              />
            </div>

            {errors?.gender ? (
              <p className="form-error" style={{ paddingLeft: 8 }}>
                {errors.gender}
              </p>
            ) : null}
          </FormGroup>
        </div>

        <FormGroup>
          <Label>About / Bio</Label>
          <Input
            type="textarea"
            name="about"
            id="textarea-input"
            maxLength={BIO_MAX_LIMIT}
            rows="3"
            value={formFields?.introduction}
            onChange={(e) => _updateFieldValue("introduction", e.target.value)}
            onFocus={() => _onAboutFieldFocus()}
            onBlur={() => _onBlurHandler("introduction")}
          />
          {isAboutFieldFocus ? (
            <div className="text-right" style={{ color: "#828282" }}>
              {BIO_MAX_LIMIT - formFields.introduction.length} left
            </div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Occupation</Label>
          <Input
            type="text"
            name="occupation"
            value={formFields.occupation}
            onChange={(e) => _updateFieldValue("occupation", e.target.value)}
            onBlur={() => _onBlurHandler("occupation")}
          />
        </FormGroup>

        {/* <FormGroup>
              <Label>Interests / Hobby</Label>
              <Input
                type="text"
                name="hobby"
                value={formFields.hobby}
                onChange={(e) => _updateFieldValue("hobby", e.target.value)}
                onBlur={() => _onBlurHandler("hobby")}
              />
            </FormGroup> */}

        <Label>Birthday</Label>
        <FormGroup className="dobWrap">
          <div>
            <Label>Date</Label>
            <Input
              data-test="date-dropdown"
              type="select"
              value={formFields?.birthDate}
              onChange={(e) => _updateFieldValue("birthDate", e.target.value)}
            >
              {generateDates(formFields?.birthMonth, formFields?.birthYear).map(
                (date) => (
                  <option key={`date_${date}`} value={date}>
                    {date}
                  </option>
                )
              )}
            </Input>
          </div>
          <div className="mx-2">
            <Label>Month</Label>
            <Input
              data-test="month-dropdown"
              type="select"
              value={formFields?.birthMonth}
              onChange={(e) => _updateFieldValue("birthMonth", e.target.value)}
            >
              {MonthsInEngConfig.map((month) => (
                <option key={`month_${month.short_name}`} value={month?.month}>
                  {month?.short_name}
                </option>
              ))}
            </Input>
          </div>
          <div>
            <Label>Year</Label>
            <Input
              data-test="year-dropdown"
              type="select"
              value={formFields.birthYear}
              onChange={(e) => _updateFieldValue("birthYear", e.target.value)}
            >
              {birthYearWithMinimumAge(MIN_SIGNUP_AGE).map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </Input>
            {errors?.birthYear ? (
              <div className="form-error" style={{ paddingLeft: 8 }}>
                {formFields.birthYear.error}
              </div>
            ) : null}
          </div>
        </FormGroup>

        <div>
          <Button
            className="themeBtn saveBtn"
            onClick={() => _handleSubmit()}
            disabled={isLoading}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Save
          </Button>

          <Button
            color="link"
            className="deleteAccountBtn"
            onClick={() => _toggleDeleteModal(true)}
            disabled={isDeleteAccountLoading}
          >
            {isDeleteAccountLoading ? (
              <span>
                <i className="fa fa-spinner fa-spin mr-1" />
              </span>
            ) : (
              <i className="fa fa-trash-o mr-2" />
            )}
            Delete My Account
          </Button>
        </div>

        <hr />

        <SocialMediaLinks />
      </div>
      {/* </div> */}

      <ImageCropUploaderModal
        isOpen={isCropModalOpen}
        selectedPhoto={cropModalContent}
        resetPhoto={() => _resetPhoto()}
        onSuccess={_saveCroppedPhoto}
        onDismiss={() => _toggleCropModal(false)}
      />

      <PromptModal
        isVisible={isDeleteModalOpen}
        message={
          "You will not be able to use the remaining period of your subscriptions or any paid content you have unlocked. Are you sure, you want to delete your account?"
        }
        successButtonText="Yes, Delete My Account"
        isEmphasisNo={true}
        onSuccess={() => _deleteAccount()}
        onDismiss={() => _toggleDeleteModal(false)}
      />

      <ViewAchievementsModal
        isOpen={viewAchievementsModal.isOpen}
        toggle={() => _toggleViewAchievementsModal()}
      />
    </>
  );
};

export default ProfileSettings;
