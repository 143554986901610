import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  showToast,
  getSocialLink,
  errorHandler,
  openSocialLink,
  formatDate,
  extractQueryParams,
  getLowResolutionLink,
  getWindowDimensions,
} from "../helper-methods";
import {
  pauseSubscription,
  changeSubscription,
  changeSubscriptionAmount,
} from "../http-calls";
import {
  APP_LOGO,
  CURRENCY_SYMBOL,
  DEFAULT_PROFILE_PICTURE,
  DISCORD_CONFIGURATION,
} from "../config";
import ProcessPayment from "../payment/index";
import DiscordConfigurationModal from "../components/modals/DiscordConfigurationModal";
import { APP_NAME } from "../config";
import PaymentHoc from "../components/PaymentHoc";
import ManageSubscriptionModal from "../components/modals/ManageSubscriptionModal";
import PromptModal from "../components/modals/PromptModal";
import {
  getAndUpdateUserSubscriptions,
  hideLoader,
  showLoader,
  updateOnlyUserData,
} from "../redux/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomLoader from "../components/custom/CustomLoader";
import CancelSubscriptionModal from "../components/modals/CancelSubscriptionModal";
import SkeletonLoading from "../components/SkeletonLoading";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import SvgIcons from "../components/SvgIcons";

const ManageSubscriptions = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);
  const userSubscriptions = useSelector((state) => state?.userSubscriptions);

  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState({
    isOpen: false,
    selectedSubscription: null,
    isRenewal: false,
    isResume: false,
  });
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState({
    isOpen: false,
    amount: 0,
  });
  const [promptModal, setPromptModal] = useState({
    isOpen: false,
    message: "",
  });
  const [discordConfigurationModal, setDiscordConfigurationModal] = useState({
    isOpen: false,
    influencerId: null,
  });
  const [manageSubscriptionModal, setManageSubscriptionModal] = useState({
    isOpen: false,
    isRenewal: false,
    selectedTier: "",
    selectedSubscription: null,
  });
  const [loading, setLoading] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [paymentApiData, setPaymentApiData] = useState({});
  const [highlightedCreator, setHighlightedCreator] = useState(null);

  const _getAndUpdateUserSubscriptions = () => {
    return new Promise(async (resolve) => {
      try {
        setLoading(true);
        await getAndUpdateUserSubscriptions()(dispatch);
        setLoading(false);
        resolve(true);

        const { username } = extractQueryParams();

        if (username) {
          const element = document.getElementById(username);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            setHighlightedCreator(username);
          }
        }
      } catch (error) {
        errorHandler(error);
        setLoading(false);
        resolve(false);
      }
    });
  };

  const _toggleCancelSubscriptionModal = ({
    isOpen = false,
    selectedSubscription = null,
    isRenewal = false,
    isResume = false,
  }) => {
    setCancelSubscriptionModal({
      isOpen,
      selectedSubscription,
      isRenewal,
      isResume,
    });
  };

  const _toggleDiscordConfigurationModal = (
    isOpen = false,
    influencerId = null
  ) => {
    setDiscordConfigurationModal({
      isOpen,
      influencerId,
    });
  };

  const _pauseSubscription = async () => {
    const subscriptionId =
      cancelSubscriptionModal?.selectedSubscription?._influencer?._id;

    dispatch(showLoader("Processing Request"));

    try {
      await pauseSubscription(subscriptionId);
      // always resolve
      await _getAndUpdateUserSubscriptions();

      _toggleCancelSubscriptionModal({});

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };

  const _renewSubscription = async () => {
    try {
      const subscriptionId =
        cancelSubscriptionModal?.selectedSubscription?._influencer?._id;

      dispatch(showLoader("Processing Request"));

      const paymentApiData = {
        url: `/fan/subscriptions/renew/${subscriptionId}`,
        url3DSecure: `/payment/intent/subscriptions/renew/${subscriptionId}`,
      };

      const influencer =
        cancelSubscriptionModal?.selectedSubscription?._influencer;

      paymentApiData.influencer = influencer;

      const { transaction } = await ProcessPayment.startPaymentProcess(
        paymentApiData,
        null,
        "Put"
      );

      // always resolve
      await _getAndUpdateUserSubscriptions();

      _toggleCancelSubscriptionModal({});

      dispatch(hideLoader());

      if (
        DISCORD_CONFIGURATION === true &&
        transaction?.subscriptionTier === "premium"
      ) {
        _toggleDiscordConfigurationModal(true, transaction._to);
      }
    } catch (error) {
      if (error?.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _redirectToInfluencerDetailsPage = (subscription) => {
    if (subscription?._influencer?.username) {
      history.push(`/influencer/${subscription?._influencer?.username}`);
    }
  };

  const _toggleManageSubscriptionModal = ({
    isOpen = false,
    selectedSubscription = null,
    selectedTier = "",
    openForBundle = false,
    isRenewal = false,
  }) => {
    // In Offer -> Subscribe via offer
    // if (
    //   selectedSubscription?.upgradeRestricted &&
    //   selectedSubscription?.downgradeRestricted
    // ) {
    //   showToast("You can not upgrade or downgrade your subscription", "error");
    //   return;
    // } else if (selectedSubscription?.upgradeRestricted) {
    //   showToast("You can not upgrade your subscription", "error");
    //   return;
    // } else if (selectedSubscription?.downgradeRestricted) {
    //   showToast("You can not downgrade your subscription", "error");
    //   return;
    // }

    if (
      selectedSubscription &&
      selectedSubscription.tier === selectedTier &&
      !openForBundle &&
      !isRenewal
    ) {
      return;
    }

    setManageSubscriptionModal({
      isOpen,
      isRenewal,
      selectedTier,
      selectedSubscription,
    });
  };

  const _getBasicPriceDetails = (subscription) => {
    let basic = {
      price: 0,
      isCurrentTier: "",
      isAvailable: true,
      bundles: [],
      isActive: true,
    };

    if (!subscription?._influencer) {
      return basic;
    }

    basic["price"] = subscription?._influencer?.subscriptionFees?.amount
      ? subscription?._influencer.subscriptionFees.amount
      : 0;

    basic["bundles"] = subscription?.bundles?.length
      ? subscription.bundles
          .filter((item) => item.tier === "basic")
          .sort(
            (obj1, obj2) => obj1.subscriptionPeriod - obj2.subscriptionPeriod
          )
      : [];

    basic["isCurrentTier"] = subscription?.tier === "basic" ? true : false;

    if (subscription.tier !== "basic" && subscription.downgradeRestricted) {
      basic["isActive"] = false;
    }

    return basic;
  };

  const _getPlusPriceDetails = (subscription) => {
    let plus = {
      price: 0,
      isCurrentTier: "",
      isAvailable: true,
      bundles: [],
      isActive: true,
    };

    if (!subscription?._influencer) {
      return plus;
    }

    plus["price"] = subscription?._influencer?.plusSubscriptionFees?.amount
      ? subscription?._influencer.plusSubscriptionFees.amount
      : subscription.tier && subscription.tier === "plus"
      ? subscription?._influencer.subscriptionFees.amount
      : 0;

    plus["bundles"] = subscription?.bundles?.length
      ? subscription.bundles.filter((item) => item.tier === "plus")
      : [];

    plus["isCurrentTier"] = subscription?.tier === "plus" ? true : false;

    if (subscription?.tier === "premium" && subscription.downgradeRestricted) {
      plus["isActive"] = false;
    }

    return plus;
  };

  const _getPremiumPriceDetails = (subscription) => {
    let premium = {
      price: 0,
      isCurrentTier: "",
      isAvailable: true,
      bundles: [],
    };

    if (!subscription?._influencer) {
      return premium;
    }

    premium["price"] = subscription?._influencer?.premiumSubscriptionFees
      ?.amount
      ? subscription?._influencer.premiumSubscriptionFees.amount
      : subscription.tier && subscription.tier === "premium"
      ? subscription?._influencer.subscriptionFees.amount
      : 0;

    premium["bundles"] = subscription?.bundles?.length
      ? subscription.bundles.filter((item) => item.tier === "premium")
      : [];

    premium["isCurrentTier"] = subscription?.tier === "premium" ? true : false;

    return premium;
  };

  const _togglePaymentConfirmationModal = (isOpen = false, amount = 0) => {
    setPaymentConfirmationModal({
      isOpen,
      amount,
    });
  };

  const _togglePromptModal = (isOpen = false, message = "") => {
    setPromptModal({
      isOpen,
      message,
    });
  };

  const _changeSubscription = async (
    tier,
    bundleCycle = "",
    selectedSubscription
  ) => {
    try {
      if (!tier) {
        showToast("Please select tier", "error");
        return;
      }

      let bundles = [];

      const newPaymentApiData = {
        influencer: selectedSubscription._influencer._id,
        tier,
      };

      if (tier === "basic") {
        bundles = _getBasicPriceDetails(selectedSubscription).bundles;
      } else if (tier === "plus") {
        bundles = _getPlusPriceDetails(selectedSubscription).bundles;
      } else if (tier === "premium") {
        bundles = _getPremiumPriceDetails(selectedSubscription).bundles;
      } else {
        showToast("Please select tier", "error");
        return;
      }

      if (bundleCycle) {
        let selectedBundle = null;
        selectedBundle = bundles.find(
          (item) => item.subscriptionPeriod === Number(bundleCycle)
        );
        if (selectedBundle) {
          newPaymentApiData.bundleId = selectedBundle?._id;
        }
      }

      setLoadingSubscription(true);
      const { amountToBePaid, subscriptionFlow, noOfDays } =
        await changeSubscriptionAmount({
          id: newPaymentApiData?.influencer,
          payload: { ...newPaymentApiData },
        });
      setLoadingSubscription(false);

      setPaymentApiData(newPaymentApiData);

      if (!amountToBePaid) {
        _togglePromptModal(
          true,
          `Do you want to ${subscriptionFlow || "change"} subscription? ${
            Math.floor(noOfDays) > 0
              ? `You will get extra ${Math.floor(noOfDays)} ${
                  Math.floor(noOfDays) > 1 ? "Days" : "Day"
                }.`
              : ""
          }`
        );
      } else {
        _togglePaymentConfirmationModal(
          true,
          Number(amountToBePaid).toFixed(2)
        );
      }
    } catch (error) {
      errorHandler(error);
      setLoadingSubscription(false);
    }
  };

  const _paymentDone = async (card = null) => {
    try {
      dispatch(showLoader("Payment is being processing"));

      const newPaymentApiData = { ...paymentApiData };

      if (card) {
        newPaymentApiData.card = card;
      }

      newPaymentApiData.url = `/fan/subscriptions/change/bundle/${newPaymentApiData.influencer}`;
      newPaymentApiData.url3DSecure = `/payment/intent/subscription/change/bundle/${newPaymentApiData.influencer}`;

      newPaymentApiData.payload = {
        tier: newPaymentApiData.tier,
        bundleId: newPaymentApiData.bundleId,
      };

      const { transaction } = await ProcessPayment.startPaymentProcess(
        newPaymentApiData,
        paymentConfirmationModal.amount,
        "Put"
      );

      _toggleManageSubscriptionModal({});

      _togglePaymentConfirmationModal();

      // always resolve
      await _getAndUpdateUserSubscriptions();

      showToast("Your subscription tier updated successfully", "success");

      dispatch(hideLoader());

      if (
        DISCORD_CONFIGURATION === true &&
        transaction?.subscriptionTier === "premium"
      ) {
        _toggleDiscordConfigurationModal(true, transaction._to);
      }
    } catch (error) {
      if (error?.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _degradeTier = async () => {
    try {
      dispatch(showLoader("Subscription is being updating"));

      _togglePromptModal();

      const payload = {
        tier: paymentApiData?.tier,
        bundleId: paymentApiData?.bundleId,
      };

      await changeSubscription({ id: paymentApiData?.influencer, payload });

      // always resolve
      await _getAndUpdateUserSubscriptions();

      showToast("Your subscription tier updated successfully", "success");

      dispatch(hideLoader());

      _togglePaymentConfirmationModal();

      _toggleManageSubscriptionModal({});
    } catch (error) {
      if (error?.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _showErrorToast = (username) => {
    showToast(`@${username || "Creator"} is currently Inactive.`, "error");
  };

  const _getCarouselMediaForCover = useCallback((influencer) => {
    const newCarousel = {};

    if (!influencer?.carouselMediaForCover?.url) {
      return newCarousel;
    }

    newCarousel.url = influencer?.carouselMediaForCover?.url;

    const carouselCrop = influencer?.carouselCrop;

    if (carouselCrop?.length) {
      const findCropObj = carouselCrop?.find(
        (each) => each.carouselIndex === 0 || each.carouselIndex === 1
      );

      if (findCropObj) {
        newCarousel.crop = findCropObj;
      }
    }

    return newCarousel;
  }, []);

  const [screenWidth, setScreenWidth] = useState(0);

  const _changeWidth = () => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    _getAndUpdateUserSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="justify-content-center noMargin">
            <Col xs="12" md="10" lg="8" xl="7" className="pgPadding">
              <div className="pgTitle">
                <div className="d-flex">
                  <h2>My Subscriptions</h2>
                  {loading ? <CustomLoader className="mx-2" /> : null}
                </div>
              </div>

              {userSubscriptions?.subscriptions?.length ? (
                userSubscriptions.subscriptions.map(
                  (subscription, subscriptionIndex) => (
                    <Card
                      id={subscription?._influencer?.username}
                      className={`subscriptionsCard ${
                        highlightedCreator ===
                        subscription?._influencer?.username
                          ? "renewSubscriptionCard"
                          : ""
                      }`}
                      key={subscriptionIndex}
                    >
                      <CardBody>
                        <div
                          {...(screenWidth <= 575.98
                            ? {
                                style: {
                                  width: screenWidth - 20,
                                  height: (screenWidth - 20) / 3,
                                },
                              }
                            : {})}
                          className="searchResultsNewWrap"
                        >
                          <div className="searchResultsNew subscriptionList">
                            {_getCarouselMediaForCover(
                              subscription?._influencer
                            )?.url ? (
                              <div className="imgCropWrap">
                                <img
                                  src={
                                    _getCarouselMediaForCover(
                                      subscription?._influencer
                                    )?.url
                                  }
                                  alt="cover"
                                  onError={(e) => (e.target.src = APP_LOGO)}
                                  className={
                                    "infCoverImg " +
                                      _getCarouselMediaForCover(
                                        subscription?._influencer
                                      )?.crop?.height ===
                                    100
                                      ? "widthAuto"
                                      : "heightAuto"
                                  }
                                  style={{
                                    transform: `translate(-${
                                      (_getCarouselMediaForCover(
                                        subscription?._influencer
                                      )?.crop?.x ||
                                      _getCarouselMediaForCover(
                                        subscription?._influencer
                                      )?.crop?.x === 0
                                        ? _getCarouselMediaForCover(
                                            subscription?._influencer
                                          )?.crop?.x
                                        : 0) + "%"
                                    }, -${
                                      (_getCarouselMediaForCover(
                                        subscription?._influencer
                                      )?.crop?.y ||
                                      _getCarouselMediaForCover(
                                        subscription?._influencer
                                      )?.crop?.y === 0
                                        ? _getCarouselMediaForCover(
                                            subscription?._influencer
                                          )?.crop?.y
                                        : 0) + "%"
                                    })`,
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="defaultCoverImg">
                                <img src={APP_LOGO} alt="cover" />
                              </div>
                            )}

                            <div className="searchResultsContentNew justify-content-center">
                              <div className="position-relative">
                                <img
                                  src={
                                    getLowResolutionLink(
                                      subscription?._influencer?.profilePicture
                                    ) || DEFAULT_PROFILE_PICTURE
                                  }
                                  alt="Profile Pic"
                                  className="img60by60 profileImgSubscription"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = DEFAULT_PROFILE_PICTURE;
                                  }}
                                  onClick={() =>
                                    _redirectToInfluencerDetailsPage(
                                      subscription
                                    )
                                  }
                                  loading="lazy"
                                />

                                {influencersOnlineData?.influencers?.[
                                  subscription?._influencer?.id
                                ] ? (
                                  <span
                                    className="fa fa-circle onlineStatus"
                                    style={{ bottom: 5, right: 3 }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="influencerName influencerNameSubscription">
                          {/* <h3
                              onClick={() =>
                                _redirectToInfluencerDetailsPage(
                                  subscription
                                )
                              }
                            >
                              {subscription._influencer.name.full}
                            </h3> */}
                          <Button
                            color="link"
                            className="otherPgsProfileID"
                            onClick={() =>
                              _redirectToInfluencerDetailsPage(subscription)
                            }
                          >
                            @{subscription._influencer.username}
                          </Button>

                          {/* the below div will only be seen in mobile view */}
                          <div className="d-inline d-md-none">
                            {subscription.isExpired ? (
                              subscription?.hasToReSubscribe ? (
                                <Button
                                  className={`cancelBilling ${
                                    !subscription?._influencer?.isActive
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    _redirectToInfluencerDetailsPage(
                                      subscription
                                    )
                                  }
                                >
                                  Subscribe
                                </Button>
                              ) : (
                                <Button
                                  className={`cancelBilling ${
                                    !subscription?._influencer?.isActive
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    subscription?._influencer?.isActive
                                      ? _toggleCancelSubscriptionModal({
                                          isOpen: true,
                                          selectedSubscription: subscription,
                                          isRenewal: true,
                                          isResume: false,
                                        })
                                      : _showErrorToast(
                                          subscription?._influencer?.username
                                        )
                                  }
                                >
                                  Renew Subscription
                                </Button>
                              )
                            ) : !subscription.isAuto &&
                              subscription.isExpired ? (
                              <Button
                                className={`restartSubscription ${
                                  !subscription?._influencer?.isActive ||
                                  !subscription?._influencer?.paymentEnabled
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() =>
                                  subscription?._influencer?.isActive &&
                                  subscription?._influencer?.paymentEnabled
                                    ? _toggleManageSubscriptionModal({
                                        isOpen: true,
                                        selectedSubscription: subscription,
                                        selectedTier: subscription.tier,
                                        openForBundle: false,
                                        isRenewal: true,
                                      })
                                    : _showErrorToast(
                                        subscription?._influencer?.username
                                      )
                                }
                              >
                                Restart Subscription
                              </Button>
                            ) : !subscription.isExpired &&
                              !subscription.isAuto ? (
                              <Button
                                className={`cancelBilling ${
                                  !subscription?._influencer?.isActive
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() =>
                                  subscription?._influencer?.isActive
                                    ? _toggleCancelSubscriptionModal({
                                        isOpen: true,
                                        selectedSubscription: subscription,
                                        isRenewal: false,
                                        isResume: true,
                                      })
                                    : _showErrorToast(
                                        subscription?._influencer?.username
                                      )
                                }
                              >
                                Resume Subscription
                              </Button>
                            ) : (
                              <Button
                                className={`cancelBilling ${
                                  !subscription?._influencer?.isActive
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() =>
                                  subscription?._influencer?.isActive
                                    ? _toggleCancelSubscriptionModal({
                                        isOpen: true,
                                        selectedSubscription: subscription,
                                        isRenewal: false,
                                        isResume: false,
                                      })
                                    : _showErrorToast(
                                        subscription?._influencer?.username
                                      )
                                }
                              >
                                Pause Subscription
                              </Button>
                            )}
                          </div>
                        </div>

                        {!subscription &&
                        subscription._influencer &&
                        subscription._influencer.social &&
                        subscription._influencer.social.length ? (
                          <div className="d-flex mt-2">
                            {getSocialLink(
                              subscription._influencer.social,
                              "facebook"
                            ).length ? (
                              <Button
                                size="sm"
                                className="btn-facebook btn-brand mr-2"
                                title={getSocialLink(
                                  subscription._influencer.social,
                                  "facebook"
                                )}
                                onClick={() =>
                                  openSocialLink(
                                    getSocialLink(
                                      subscription._influencer.social,
                                      "facebook"
                                    )
                                  )
                                }
                              >
                                <i className="fa fa-facebook" />
                              </Button>
                            ) : null}
                            {getSocialLink(
                              subscription._influencer.social,
                              "twitter"
                            ).length ? (
                              <Button
                                size="sm"
                                className="btn-twitter btn-brand mr-2"
                                title={getSocialLink(
                                  subscription._influencer.social,
                                  "twitter"
                                )}
                                onClick={() =>
                                  openSocialLink(
                                    getSocialLink(
                                      subscription._influencer.social,
                                      "twitter"
                                    )
                                  )
                                }
                              >
                                <i className="fa fa-twitter" />
                              </Button>
                            ) : null}
                            {getSocialLink(
                              subscription._influencer.social,
                              "instagram"
                            ).length ? (
                              <Button
                                size="sm"
                                className="btn-instagram btn-brand mr-2"
                                title={getSocialLink(
                                  subscription._influencer.social,
                                  "instagram"
                                )}
                                onClick={() =>
                                  openSocialLink(
                                    getSocialLink(
                                      subscription._influencer.social,
                                      "instagram"
                                    )
                                  )
                                }
                              >
                                <i className="fa fa-instagram" />
                              </Button>
                            ) : null}

                            {getSocialLink(
                              subscription._influencer.social,
                              "youtube"
                            ).length ? (
                              <Button
                                size="sm"
                                className="btn-youtube btn-brand mr-2"
                                title={getSocialLink(
                                  subscription._influencer.social,
                                  "youtube"
                                )}
                                onClick={() =>
                                  openSocialLink(
                                    getSocialLink(
                                      subscription._influencer.social,
                                      "youtube"
                                    )
                                  )
                                }
                              >
                                <i className="fa fa-youtube" />
                              </Button>
                            ) : null}
                            {getSocialLink(
                              subscription._influencer.social,
                              "tiktok"
                            ).length ? (
                              <Button
                                size="sm"
                                className="btn-tiktok btn-brand"
                                title={getSocialLink(
                                  subscription._influencer.social,
                                  "tiktok"
                                )}
                                onClick={() =>
                                  openSocialLink(
                                    getSocialLink(
                                      subscription._influencer.social,
                                      "tiktok"
                                    )
                                  )
                                }
                              >
                                <img
                                  src={"assets/img/tiktok.png"}
                                  alt="TikTok"
                                  className="tiktok"
                                  loading="lazy"
                                />
                              </Button>
                            ) : null}
                          </div>
                        ) : null}

                        <div className="subscriberPriceTier">
                          {_getBasicPriceDetails(subscription).price ? (
                            <div
                              className={
                                subscription.tier &&
                                subscription.tier === "basic"
                                  ? "currentTier"
                                  : ""
                              }
                              onClick={() =>
                                subscription?._influencer?.isActive
                                  ? _toggleManageSubscriptionModal({
                                      isOpen: true,
                                      selectedSubscription: subscription,
                                      selectedTier: "basic",
                                      openForBundle: false,
                                      isRenewal: false,
                                    })
                                  : _showErrorToast(
                                      subscription?._influencer?.username
                                    )
                              }
                            >
                              {subscription?._influencer
                                ?.multiTierSubscription ? (
                                <SvgIcons type="star" />
                              ) : null}

                              <p>
                                {CURRENCY_SYMBOL}
                                <span>
                                  {_getBasicPriceDetails(subscription).price}
                                </span>
                              </p>
                            </div>
                          ) : null}

                          {subscription?._influencer?.multiTierSubscription ? (
                            <>
                              {subscription._influencer.plusSubscriptionFees &&
                              subscription._influencer.plusSubscriptionFees
                                .amount > 0 ? (
                                <>
                                  {/* plusPrice */}
                                  <div
                                    className={
                                      subscription.tier &&
                                      subscription.tier === "plus"
                                        ? "currentTier"
                                        : ""
                                    }
                                    onClick={() =>
                                      subscription?._influencer?.isActive
                                        ? _toggleManageSubscriptionModal({
                                            isOpen: true,
                                            selectedSubscription: subscription,
                                            selectedTier: "plus",
                                            openForBundle: false,
                                            isRenewal: false,
                                          })
                                        : _showErrorToast(
                                            subscription?._influencer?.username
                                          )
                                    }
                                  >
                                    <SvgIcons type="gem" id="gems" />

                                    <p>
                                      {CURRENCY_SYMBOL}
                                      <span>
                                        {
                                          _getPlusPriceDetails(subscription)
                                            .price
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </>
                              ) : null}

                              {subscription._influencer
                                .premiumSubscriptionFees &&
                              subscription._influencer.premiumSubscriptionFees
                                .amount > 0 ? (
                                <>
                                  {/* premiumPrice */}
                                  <div
                                    className={
                                      subscription.tier &&
                                      subscription.tier === "premium"
                                        ? "currentTier"
                                        : ""
                                    }
                                    onClick={() =>
                                      subscription?._influencer?.isActive
                                        ? _toggleManageSubscriptionModal({
                                            isOpen: true,
                                            selectedSubscription: subscription,
                                            selectedTier: "premium",
                                            openForBundle: false,
                                            isRenewal: false,
                                          })
                                        : _showErrorToast(
                                            subscription?._influencer?.username
                                          )
                                    }
                                  >
                                    <SvgIcons
                                      type="diamond"
                                      className="premiumTier"
                                      id="diamond"
                                    />

                                    <p>
                                      {CURRENCY_SYMBOL}
                                      <span>
                                        {
                                          _getPremiumPriceDetails(subscription)
                                            .price
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          {subscription?.bundles?.length ? (
                            <Button
                              color="link"
                              className={`seeBundles ${
                                !subscription?._influencer?.isActive
                                  ? "disabled"
                                  : ""
                              }`}
                              style={{ pointerEvents: "visible" }}
                              onClick={() =>
                                subscription?._influencer?.isActive
                                  ? _toggleManageSubscriptionModal({
                                      isOpen: true,
                                      selectedSubscription: subscription,
                                      selectedTier: subscription.tier,
                                      openForBundle: true,
                                      isRenewal: false,
                                    })
                                  : _showErrorToast(
                                      subscription?._influencer?.username
                                    )
                              }
                            >
                              See Special Offers
                            </Button>
                          ) : null}
                        </div>

                        {subscription?._influencer?.introduction?.length ? (
                          <div className="introInSubscription">
                            <ReadMoreReadLess
                              className="readMore"
                              text={subscription?._influencer?.introduction}
                            />
                          </div>
                        ) : (
                          <p className="introInSubscription">
                            Welcome to {APP_NAME} Subscribe to unlock all of my
                            exclusive content!
                          </p>
                        )}
                      </CardBody>
                      <CardFooter>
                        <div className="subscriptionDateWrap">
                          {subscription.isExpired ? (
                            <div>
                              <h5 className="subscriptionDateTxt">
                                Subscription Ended
                              </h5>{" "}
                              <p className="subscriptionDate">
                                {formatDate(subscription.end)}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <h5 className="subscriptionDateTxt">
                                Active Since
                              </h5>{" "}
                              <p className="subscriptionDate">
                                {formatDate(subscription.start)}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="subscriptionDateWrap">
                          {/* Do not show the 'Next Billing On' & 'Date' when subscription has expired, just show the restart subscription btn */}
                          {subscription.isAuto &&
                          new Date() < new Date(subscription.end) ? (
                            <>
                              <h5 className="subscriptionDateTxt">Next Bill</h5>

                              <p className="subscriptionDate d-inline-block">
                                {formatDate(subscription.end)}
                              </p>
                            </>
                          ) : null}
                        </div>

                        {/* the below div is only for web view */}
                        <div className="d-none d-md-inline">
                          {subscription.isExpired ? (
                            subscription?.hasToReSubscribe ? (
                              <Button
                                className={`cancelBilling ${
                                  !subscription?._influencer?.isActive
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() =>
                                  _redirectToInfluencerDetailsPage(subscription)
                                }
                              >
                                Subscribe
                              </Button>
                            ) : (
                              <Button
                                className={`cancelBilling ${
                                  !subscription?._influencer?.isActive
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() =>
                                  subscription?._influencer?.isActive
                                    ? _toggleCancelSubscriptionModal({
                                        isOpen: true,
                                        selectedSubscription: subscription,
                                        isRenewal: true,
                                        isResume: false,
                                      })
                                    : _showErrorToast(
                                        subscription?._influencer?.username
                                      )
                                }
                              >
                                Renew Subscription
                              </Button>
                            )
                          ) : !subscription.isAuto && subscription.isExpired ? (
                            <Button
                              className={`restartSubscription ${
                                !subscription?._influencer?.isActive ||
                                !subscription._influencer.paymentEnabled
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() =>
                                subscription?._influencer?.isActive &&
                                subscription._influencer.paymentEnabled
                                  ? _toggleManageSubscriptionModal({
                                      isOpen: true,
                                      selectedSubscription: subscription,
                                      selectedTier: subscription.tier,
                                      openForBundle: false,
                                      isRenewal: true,
                                    })
                                  : _showErrorToast(
                                      subscription?._influencer?.username
                                    )
                              }
                            >
                              Restart Subscription
                            </Button>
                          ) : !subscription.isExpired &&
                            !subscription.isAuto ? (
                            <Button
                              className={`cancelBilling ${
                                !subscription?._influencer?.isActive
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() =>
                                subscription?._influencer?.isActive
                                  ? _toggleCancelSubscriptionModal({
                                      isOpen: true,
                                      selectedSubscription: subscription,
                                      isRenewal: false,
                                      isResume: true,
                                    })
                                  : _showErrorToast(
                                      subscription?._influencer?.username
                                    )
                              }
                            >
                              Resume Subscription
                            </Button>
                          ) : (
                            <Button
                              className={`cancelBilling ${
                                !subscription?._influencer?.isActive
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() =>
                                subscription?._influencer?.isActive
                                  ? _toggleCancelSubscriptionModal({
                                      isOpen: true,
                                      selectedSubscription: subscription,
                                      isRenewal: false,
                                      isResume: false,
                                    })
                                  : _showErrorToast(
                                      subscription?._influencer?.username
                                    )
                              }
                            >
                              Pause Subscription
                            </Button>
                          )}
                        </div>
                      </CardFooter>
                    </Card>
                  )
                )
              ) : loading ? (
                <SkeletonLoading type={"subscription"} count={4} />
              ) : (
                <div className="d-flex justify-content-center align-items-center w-100 flex-column">
                  <Button
                    className="customNotificationText"
                    onClick={() => {
                      history.push("/search");
                    }}
                  >
                    You have not subscribed to any creators
                    <br />
                    Search for creators by keyword, name or category.
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <PaymentHoc
        isVisible={paymentConfirmationModal.isOpen}
        amount={paymentConfirmationModal.amount}
        influencer={paymentApiData?.influencer}
        onPaymentConfirmation={(card) => _paymentDone(card)}
        onDismiss={() => _togglePaymentConfirmationModal()}
      />

      {manageSubscriptionModal?.selectedSubscription ? (
        <ManageSubscriptionModal
          isOpen={manageSubscriptionModal.isOpen}
          selectedTier={manageSubscriptionModal.selectedTier}
          isRenewal={manageSubscriptionModal.isRenewal}
          subscription={manageSubscriptionModal.selectedSubscription}
          toggle={() => _toggleManageSubscriptionModal({})}
          getBasicPriceDetails={_getBasicPriceDetails}
          getPlusPriceDetails={_getPlusPriceDetails}
          getPremiumPriceDetails={_getPremiumPriceDetails}
          basicBundles={
            _getBasicPriceDetails(manageSubscriptionModal.selectedSubscription)
              .bundles
          }
          premiumBundles={
            _getPremiumPriceDetails(
              manageSubscriptionModal.selectedSubscription
            ).bundles
          }
          plusBundles={
            _getPlusPriceDetails(manageSubscriptionModal.selectedSubscription)
              .bundles
          }
          changeSubscription={_changeSubscription}
          loading={loadingSubscription}
        />
      ) : null}

      <PromptModal
        isVisible={promptModal.isOpen}
        message={promptModal.message}
        onSuccess={() => _degradeTier()}
        onDismiss={() => _togglePromptModal()}
      />

      <DiscordConfigurationModal
        isOpen={discordConfigurationModal.isOpen}
        influencerId={discordConfigurationModal.influencerId}
        toggle={() => _toggleDiscordConfigurationModal()}
      />

      <CancelSubscriptionModal
        isOpen={cancelSubscriptionModal.isOpen}
        selectedSubscription={cancelSubscriptionModal.selectedSubscription}
        influencer={cancelSubscriptionModal.selectedSubscription?._influencer}
        isRenewal={cancelSubscriptionModal.isRenewal}
        isResume={cancelSubscriptionModal.isResume}
        toggle={() => _toggleCancelSubscriptionModal({})}
        renewSubscription={_renewSubscription}
        pauseSubscription={_pauseSubscription}
      />
    </>
  );
};

export default ManageSubscriptions;
