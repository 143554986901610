import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Progress,
} from "reactstrap";
import VisibilitySensor from "react-visibility-sensor";
import cuid from "cuid";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  formatDate,
  showToast,
  calculateContentType,
  formatCurrencyValue,
  mentionUserInputParse,
  errorHandler,
  totalCommentsCount,
  getCommentIconClassName,
  getLikeIconClassName,
  getAspectRatioClass,
  getLowResolutionLink,
  copyPostPublicLink,
  getResolution,
  getFeedImageSrc,
} from "../../helper-methods";
import {
  dislikePost,
  getComments,
  likePost,
  postCommentOnFeed,
  likeComment,
  createPaymentIntent,
} from "../../http-calls";
import {
  DEFAULT_COVER_PICTURE,
  DEFAULT_PROFILE_PICTURE,
} from "../../config/index";
import ProcessPayment from "../../payment/index";
import PostCommentSection from "../PostCommentSection";
import FeedViewerCarousel from "./FeedViewerCarousel";
import ContributeToGoalModal from "../modals/ContributeToGoalModal";
import FeedTipModal from "../modals/FeedTipModal";
import PaymentHoc from "../PaymentHoc";
import FeedPaymentTier from "../FeedPaymentTier";
import ShowMediaModal from "../modals/ShowMediaModal";
import ReportPostModal from "../modals/ReportPostModal";
import {
  hideLoader,
  showLoader,
  updateOnlyUserData,
} from "../../redux/actions";
import { getConfiguredString } from "../../helper-methods/configureString";
import SkeletonLoading from "../SkeletonLoading";
import EventViewer from "./EventViewer";
import PayPerViewer from "./PayPerViewer";
import FolderViewer from "./FolderViewer";
import PollViewer from "./PollViewer";
import TextViewer from "./TextViewer";
import VideoViewer from "./VideoViewer";
import ErrorBoundary from "../ErrorBoundary";
import AudioViewer from "./AudioViewer";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import SvgIcons from "../SvgIcons";
import ImageQualitySetting from "../ImageQualitySetting";
import ReadMoreReadLess from "../custom/ReadMoreReadLess";

const FeedViewer = ({
  feed,
  feedIndex,
  lastElementRef,
  loadingFeeds,
  onComment,
  toggleLikePost,
  onPaymentComplete,
  subscriptionData,
  feedBundles,
  onTierUpgrade,
  selectPollOption,
  scrollToTop = () => {},
  updateCommentsCount = () => {},
  customClassname,
  isProfilePage,
  toggleSubscriptionModal = () => {},
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);
  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const previewVideoTimerRef = useRef();
  const timerIntervalRef = useRef({});

  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [commentIdLikeLoading, setCommentIdLikeLoading] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);
  const [paymentApiData, setPaymentApiData] = useState({
    url3DSecure: "",
    url: "",
    payload: {},
  });
  const [paymentHoc, setPaymentHoc] = useState({
    isOpen: false,
    amount: null,
  });
  const [feedTipModal, setFeedTipModal] = useState({
    isOpen: false,
  });
  const [contributeToGoalModal, setContributeToGoalModal] = useState({
    isOpen: false,
  });
  const [flagModal, setFlagModal] = useState({
    isOpen: false,
  });
  const [showMediaModal, setShowMediaModal] = useState({
    isOpen: false,
    externalLink: null,
    contentType: null,
  });
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [imageQuality, setImageQuality] = useState("oneZeroEightZero");

  const imageQualityConfig = {
    360: "threeSixZero",
    480: "fourEightZero",
    720: "sevenTwoZero",
    1080: "oneZeroEightZero",
    Original: "raw",
  };

  const _handleScreenResolution = () => {
    const currentResolution = getResolution();

    if (Object.keys(imageQualityConfig).includes(`${currentResolution}`)) {
      setImageQuality(imageQualityConfig[currentResolution]);
    }
  };

  useEffect(() => {
    const newPaymentApiData = { ...paymentApiData };

    if (feed?.hasOwnProperty("_folder")) {
      newPaymentApiData.url3DSecure = "/payment/intent/folder";
      newPaymentApiData.url = "/payment/folder";
      newPaymentApiData.payload = {
        folderId: feed?._folder?._id,
        postId: feed?._id,
      };
    } else {
      newPaymentApiData.url3DSecure = "/payment/intent/post";
      newPaymentApiData.url = "/payment/post";
      newPaymentApiData.payload = {
        influencerId: feed?._influencer?.id,
        postId: feed?._id,
      };
    }

    setPaymentApiData(newPaymentApiData);

    _handleScreenResolution();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  const _getAllComments = async () => {
    try {
      setCommentsLoading(true);

      const res = await getComments(feed._id);

      const newComments = res.comments;

      setComments(newComments);

      updateCommentsCount({
        index: feedIndex,
        value: totalCommentsCount(newComments),
      });

      setCommentsLoading(false);
    } catch (error) {
      updateCommentsCount({
        index: feedIndex,
        value: totalCommentsCount(comments),
      });
      setComments(comments);
      setCommentsLoading(false);
    }
  };

  const _toggleCommentSection = () => {
    if (!isCommentSectionOpen) {
      _getAllComments();
    }
    setIsCommentSectionOpen((prev) => !prev);
  };

  const _postComment = async (comment) => {
    try {
      const newComments = [...comments];

      const tempId = cuid();

      newComments.unshift({
        category: "comment",
        content: comment,
        createdAt: new Date().toISOString(),
        tempId: tempId,
        isSubCommnet: false,
        isLocalComment: true,
        _postId: feed?._id,
        _user: {
          name: userData?.user?.name,
          profilePicture: userData?.user?.profilePicture,
          username: userData?.user?.username,
          userType: userData?.user?.userType,
          _id: userData?.user?._id,
          id: userData?.user?.id,
        },
      });
      setComments(newComments);
      onComment(feedIndex, true);

      await postCommentOnFeed({
        category: "comment",
        content: comment,
        id: feed?._id,
      });

      showToast("Comment has been added", "success");
    } catch (error) {
      errorHandler(error);
    } finally {
      _getAllComments();
    }
  };

  const contentTypeCount = useMemo(() => {
    const [imageCount, videoCount, audioCount] = [
      calculateContentType(feed?._contents, "image"),
      calculateContentType(feed?._contents, "video"),
      calculateContentType(feed?._contents, "audio"),
    ];

    return { image: imageCount, video: videoCount, audio: audioCount };
  }, [feed?._contents]);

  const _toggleLikePost = async () => {
    try {
      if (feed?.isDeleted) {
        return;
      }

      if (timerIntervalRef?.like) clearInterval(timerIntervalRef.like);

      if (feed?.hasOwnProperty("_isLiked") && feed?._isLiked) {
        // like to dislike
        toggleLikePost(feedIndex, false);

        timerIntervalRef.like = setTimeout(async () => {
          try {
            await dislikePost(feed?._id);
          } catch (error) {
            toggleLikePost(feedIndex, true, true);
            errorHandler(error);
          }
        }, 1000);
      } else if (!feed?.hasOwnProperty("_isLiked") || !feed?._isLiked) {
        // dislike to like
        toggleLikePost(feedIndex, true);

        timerIntervalRef.like = setTimeout(async () => {
          try {
            await likePost(feed?._id);
          } catch (error) {
            toggleLikePost(feedIndex, false, true);
            errorHandler(error);
          }
        }, 1000);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _togglePaymentHoc = (isOpen = false) => {
    let amount = null;

    if (feed?.hasOwnProperty("_folder")) {
      amount = feed?._folder?.price;
    } else {
      amount = feed?.price;
    }

    setPaymentHoc({
      isOpen,
      amount,
    });
  };

  const _toggleFeedTipModal = (isOpen = false) => {
    if (isOpen && feed?.isDeleted) {
      return;
    }

    setFeedTipModal({
      isOpen,
    });
  };

  const _toggleContributeToGoalModal = (isOpen = false) => {
    if (isOpen && feed?.isDeleted) {
      return;
    }

    setContributeToGoalModal({
      isOpen,
    });
  };

  const _toggleFlagModal = (isOpen = false) => {
    if (isOpen && feed?.isDeleted) {
      return;
    }

    setFlagModal({
      isOpen,
    });
  };

  const _onFlagConfirmation = () => {
    showToast("Successfully flagged", "success");
    _toggleFlagModal();
  };

  const _onContributeToGoalSuccess = (feedIndex) => {
    showToast("Contributed successfully", "success");

    onPaymentComplete(feedIndex);

    _toggleContributeToGoalModal();
  };

  const _unlockPost = async (amountToPay, card = null) => {
    dispatch(showLoader("Payment Processing"));

    const newPaymentApiData = { ...paymentApiData };

    if (card) {
      newPaymentApiData.payload.card = card;
    }

    newPaymentApiData.influencer = feed?._influencer;

    try {
      await ProcessPayment.startPaymentProcess(newPaymentApiData, amountToPay);

      _paymentSuccess();

      dispatch(hideLoader());
    } catch (error) {
      if (error?.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _subscribe = () => {
    try {
      // window.top.scrollTo(0, 0);
      // scrollToTop();
      toggleSubscriptionModal(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const _goToPostDetailsPage = (isSubscriptionRequired = false) => {
    if (history.location.pathname.includes("/post")) {
      history.replace(`/influencer/${feed?._influencer?.username}`);
    } else {
      if (isSubscriptionRequired) {
        return;
      } else {
        if (feed?.hasOwnProperty("_folder")) {
          if (!feed?._folderPaid) {
            showToast(
              `Select UNLOCK and make a payment to gain access to ${feed?._influencer?.username}'s vaulted content`
            );
          } else {
            history.push(`/vault/folder/${feed?._folder?._id}`);
          }
        } else {
          history.push(`/post/${feed?._id}`);
        }
      }
    }
  };

  const _toggleLikeComment = async (commentID, isLiked) => {
    const newComments = [...comments];
    const findComment = newComments?.find((each) => each?._id === commentID);
    setCommentIdLikeLoading(commentID);
    try {
      if (findComment) {
        if (isLiked) {
          findComment._isLiked = 0;
        } else {
          findComment._isLiked = 1;
        }
        setComments(newComments);
      }

      const payload = {};
      if (isLiked) {
        payload.unlike = true;
      } else {
        payload.like = true;
      }
      await likeComment(commentID, payload);

      _getAllComments();
    } catch (error) {
      if (findComment) {
        if (isLiked) {
          findComment._isLiked = 1;
        } else {
          findComment._isLiked = 0;
        }
        setComments(newComments);
      }
      errorHandler(error);
    } finally {
      setCommentIdLikeLoading(false);
    }
  };

  const _startStopPreviewVideo = (isPlayVideoPreview = false) => {
    if (feed?.previewVideoUrl || feed?.previewVideoHlsLink) {
      setIsPlayVideoPreview(isPlayVideoPreview);
    }
  };

  const _onChangeVisibility = (isVisible) => {
    if (previewVideoTimerRef?.current)
      clearTimeout(previewVideoTimerRef.current);

    if (feed?.previewVideoUrl || feed?.previewVideoHlsLink) {
      if (isVisible) {
        previewVideoTimerRef.current = setTimeout(() => {
          _startStopPreviewVideo(true);
        }, 4000);
      } else {
        _startStopPreviewVideo(false);
      }
    }
  };

  const _renderPost = () => {
    let isSubscriptionRequired = false;

    if (
      subscriptionData &&
      subscriptionData.hasOwnProperty("isSubscribed") &&
      subscriptionData.hasOwnProperty("isSubscriptionExpired")
    ) {
      if (
        !subscriptionData.isSubscribed ||
        subscriptionData.isSubscriptionExpired
      ) {
        isSubscriptionRequired = true;
      }
    }

    let isPublic = feed?.isPublic;
    let isFree = feed?.isFree;
    let isLocked = false;

    /* (!public || !isFree) => post is not free-for-all type*/
    if (!isPublic || !isFree) {
      if (!isPublic) {
        if (feed?.hasOwnProperty("_isPaid")) {
          if (feed?._isPaid > 0) {
            isLocked = false;
          } else {
            isLocked = true;
          }
        } else {
          isLocked = true;
        }
      }

      // add extra checking
      isLocked = isSubscriptionRequired ? true : isLocked;
      isPublic = isSubscriptionRequired ? false : isPublic;
    }

    let goalDiv = null;

    if (
      isPublic &&
      feed?.category === "goal" &&
      feed?.goalAmount >= 0 &&
      feed?.goalAmountReached >= 0
    ) {
      // the below data is for "Set a Goal". It will only show when the creator adds a post with a "Goal".
      goalDiv = (
        <div className="goalDataWrap">
          {!feed?.isGoalPreClosed ? (
            <p>
              Help @{feed?._influencer?.username || "N/A"} achieve their GOAL
            </p>
          ) : null}

          <Progress value={feed?.goalAmountReached} max={feed?.goalAmount} />

          <div className="goalStatus">
            <span>
              {formatCurrencyValue(feed?.goalAmountReached)} out of{" "}
              {formatCurrencyValue(feed?.goalAmount)}
            </span>

            {/* once the user clicks the below btn, a modal shows up where the user will enter the amount" */}
            {/* when the creator closes any particular GOAL, hide the btn. */}
            {!feed?.isGoalPreClosed ? (
              <Button onClick={() => _toggleContributeToGoalModal(true)}>
                Contribute
              </Button>
            ) : null}
          </div>
        </div>
      );
    }

    if (feed?.hasOwnProperty("_folder")) {
      return (
        <FolderViewer
          feed={feed}
          isSubscriptionRequired={isSubscriptionRequired}
          toggleCommentSection={_toggleCommentSection}
          toggleLikePost={_toggleLikePost}
          toggleFlagModal={_toggleFlagModal}
          goToPostDetailsPage={_goToPostDetailsPage}
          toggleFeedTipModal={_toggleFeedTipModal}
          togglePaymentHoc={_togglePaymentHoc}
          scrollToTop={scrollToTop}
        />
      );
    } else if (feed?.hasOwnProperty("_event")) {
      return (
        <EventViewer
          feed={feed}
          isSubscriptionRequired={isSubscriptionRequired}
          toggleCommentSection={_toggleCommentSection}
          toggleLikePost={_toggleLikePost}
          toggleFlagModal={_toggleFlagModal}
          goToPostDetailsPage={_goToPostDetailsPage}
          toggleFeedTipModal={_toggleFeedTipModal}
          scrollToTop={scrollToTop}
        />
      );
    } else if (feed?.hasOwnProperty("_payperview")) {
      return (
        <PayPerViewer
          feed={feed}
          isSubscriptionRequired={isSubscriptionRequired}
          toggleCommentSection={_toggleCommentSection}
          toggleLikePost={_toggleLikePost}
          toggleFlagModal={_toggleFlagModal}
          goToPostDetailsPage={_goToPostDetailsPage}
          toggleFeedTipModal={_toggleFeedTipModal}
          scrollToTop={scrollToTop}
        />
      );
    } else if (feed?.category === "general" || feed?.category === "goal") {
      if (feed?._contents?.length > 1) {
        return (
          <Card className="cardFeed">
            <CardHeader>
              <div className="d-flex align-items-center">
                <div
                  className="mr-1 position-relative"
                  // onClick={() => _goToPostDetailsPage(isSubscriptionRequired)}
                >
                  <img
                    src={
                      getLowResolutionLink(feed?._influencer?.profilePicture) ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile Img"
                    className="userImg"
                    loading="lazy"
                  />
                  {/* online status */}
                  {influencersOnlineData?.influencers?.[
                    feed?._influencer?._id
                  ] ? (
                    <span className="fa fa-circle onlineStatus" />
                  ) : null}
                </div>
                <div
                  onClick={() => _goToPostDetailsPage(isSubscriptionRequired)}
                >
                  {/* <h4>
                      {post._influencer ? post._influencer.name.full : ""}
                    </h4> */}
                  <div className="profileID">
                    @{feed?._influencer?.username || "N/A"}
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {feed?.text?.length ? (
                <ReadMoreReadLess
                  text={mentionUserInputParse(feed.text)}
                  count={50}
                  countWithObject={8}
                />
              ) : null}

              <div className="feedCarousel">
                {!isPublic && isLocked ? (
                  <>
                    <VisibilitySensor
                      onChange={(isVisible) => _onChangeVisibility(isVisible)}
                    >
                      {(feed?.previewVideoUrl || feed?.previewVideoHlsLink) &&
                      isPlayVideoPreview ? (
                        <>
                          {/* post preview video */}
                          {/* custom video player support hls */}
                          <CustomVideoPlayer
                            className={`${getAspectRatioClass(
                              feed?.previewVideoUrlAspectRatio
                            )}`}
                            onEnded={() => _startStopPreviewVideo()}
                            src={feed?.previewVideoUrl}
                            hlsLink={feed?.previewVideoHlsLink}
                            duration={feed?.previewVideoUrlDuration}
                            autoPlay
                            muted
                          />
                          <div className="videoPreviewTag-Feed">
                            Video Preview
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            className="mediaPhoto-Feed"
                            src={
                              feed?.preview ||
                              feed?._contents?.[0]?._content?.thumbnail ||
                              DEFAULT_COVER_PICTURE
                            }
                            onError={(e) =>
                              (e.target.src = DEFAULT_COVER_PICTURE)
                            }
                            style={{
                              filter: !feed?.preview ? "blur(10px)" : "",
                            }}
                            alt="Cover Img"
                            loading="lazy"
                          />
                        </>
                      )}
                    </VisibilitySensor>

                    <div className="postLockedOverlay">
                      <SvgIcons type="lock" className="iconLock" />

                      <div className="feedContentCount">
                        {contentTypeCount.image ? (
                          <div>
                            <i
                              className="fa fa-image"
                              style={{
                                verticalAlign: -1,
                                marginLeft: 1,
                              }}
                            />
                            <span>{contentTypeCount.image}</span>
                          </div>
                        ) : null}

                        {contentTypeCount.video ? (
                          <div>
                            <i className="fa fa-video-camera" />
                            <span>{contentTypeCount.video}</span>
                          </div>
                        ) : null}

                        {contentTypeCount.audio ? (
                          <div>
                            <i
                              className="fa fa-microphone"
                              style={{ verticalAlign: -1.5 }}
                            />
                            <span>{contentTypeCount.audio}</span>
                          </div>
                        ) : null}
                      </div>

                      {isSubscriptionRequired ? (
                        <Button
                          className="paymentBtnTier"
                          disabled={!feed?._influencer?.paymentEnabled}
                          onClick={() => _subscribe()}
                        >
                          Subscribe
                        </Button>
                      ) : (
                        <FeedPaymentTier
                          subscription={feed?.subscription}
                          influencer={feed?._influencer}
                          paymentFor={feed}
                          feedBundles={feedBundles}
                          requestUpdate={() => onTierUpgrade()}
                          unlockMethod={(amountToPay, card) =>
                            _unlockPost(amountToPay, card)
                          }
                          onPaymentComplete={onPaymentComplete}
                          feedIndex={feedIndex}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <ErrorBoundary>
                    {/* multi post carousel */}
                    <FeedViewerCarousel
                      contents={feed?._contents}
                      feed={feed}
                      imageQuality={imageQuality}
                      setImageQuality={setImageQuality}
                    />
                  </ErrorBoundary>
                )}
              </div>
            </CardBody>

            {/* goal div add here */}
            {goalDiv ? goalDiv : null}

            <CardFooter className={"footerFeed"}>
              <div className="d-flex">
                <Button
                  className={
                    "mr-2 mr-sm-3 " +
                    (isSubscriptionRequired ? "disabled-feed-options" : "")
                  }
                  onClick={() => _toggleLikePost()}
                >
                  <i className={getLikeIconClassName(feed)} />
                </Button>

                <Button
                  className={
                    "mr-2 mr-sm-3 " +
                    (isSubscriptionRequired ? "disabled-feed-options" : "")
                  }
                  onClick={() => _toggleCommentSection()}
                >
                  <i className={getCommentIconClassName(feed)} />
                </Button>

                <Button
                  disabled={!feed?._influencer?.paymentEnabled}
                  className={
                    "mr-2 mr-sm-3 " +
                    (isSubscriptionRequired ? "disabled-feed-options" : "")
                  }
                  onClick={() => _toggleFeedTipModal(true)}
                >
                  <img
                    src="/assets/img/dollar.png"
                    alt="Dollar"
                    loading="lazy"
                  />
                </Button>

                {(!isPublic && isLocked) || isProfilePage ? null : (
                  <Button className="ml-auto mr-3">
                    <ImageQualitySetting
                      id={`feed_carousel_${feed?._id}`}
                      imageQuality={imageQuality}
                      setImageQuality={setImageQuality}
                      isFeed={true}
                    />
                  </Button>
                )}

                <Button
                  className={
                    (!isPublic && isLocked) || isProfilePage
                      ? "ml-auto "
                      : "" +
                        (isSubscriptionRequired
                          ? feed?.isPublic && feed?.isFree
                            ? ""
                            : "disabled-feed-options"
                          : "")
                  }
                  title="Share"
                  onClick={() =>
                    copyPostPublicLink({
                      influencerUsername: feed?._influencer?.username,
                      postId: feed?._id,
                    })
                  }
                >
                  <img
                    src="/assets/img/quick-link.png"
                    alt="share"
                    loading="lazy"
                    style={{ verticalAlign: -2 }}
                  />
                </Button>

                <Button
                  title="Report"
                  className={
                    "ml-3 ml-sm-3 " +
                    (isSubscriptionRequired ? "disabled-feed-options" : "")
                  }
                  onClick={() => _toggleFlagModal(true)}
                >
                  <i className="fa fa-flag-o" />
                </Button>
              </div>

              {feed?.likes || feed?.comments ? (
                <div className="mt-1">
                  {feed?.likes ? (
                    <span>
                      {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
                    </span>
                  ) : null}
                  {/* {feed?.comments ? (
                    <span>
                      {feed?.comments}{" "}
                      {feed?.comments > 1 ? "Comments" : "Comment"}
                    </span>
                  ) : null} */}
                </div>
              ) : null}

              {/* post time */}
              <div className="postTime-Feed">{formatDate(feed?.startDate)}</div>
            </CardFooter>
          </Card>
        );
      } else if (feed?._contents?.length === 1) {
        switch (feed?._contents?.[0]?.contentType) {
          case "image": {
            // contains only one image
            return (
              <Card className="cardFeed">
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <div
                      className="mr-1 position-relative"
                      onClick={() =>
                        _goToPostDetailsPage(isSubscriptionRequired)
                      }
                    >
                      <img
                        src={
                          getLowResolutionLink(
                            feed?._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg"
                        loading="lazy"
                      />
                      {/* online status */}
                      {influencersOnlineData?.influencers?.[
                        feed?._influencer?._id
                      ] ? (
                        <span className="fa fa-circle onlineStatus" />
                      ) : null}
                    </div>
                    <div>
                      {/* <h4>
                        {post._influencer ? post._influencer.name.full : ""}
                      </h4> */}
                      <div
                        className="profileID"
                        onClick={() =>
                          _goToPostDetailsPage(isSubscriptionRequired)
                        }
                      >
                        @{feed?._influencer?.username || "N/A"}
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {feed?.text?.length ? (
                    <ReadMoreReadLess
                      text={mentionUserInputParse(feed.text)}
                      count={50}
                      countWithObject={8}
                    />
                  ) : null}

                  <div className="mediaPhotoWrap-Feed">
                    {isLoadingContent ? (
                      <div className="processingContent">
                        <p>We are processing your content</p>
                        <Button onClick={() => setIsLoadingContent(false)}>
                          Reload
                        </Button>
                      </div>
                    ) : (
                      <img
                        className="mediaPhoto-Feed"
                        src={getFeedImageSrc({
                          feed,
                          isPublic,
                          isLocked,
                          imageQuality,
                        })}
                        // onError={(e) => {
                        //   isPublic
                        //     ? setIsLoadingContent(true)
                        //     : !isPublic && !isLocked
                        //     ? setIsLoadingContent(true)
                        //     : (e.target.src = DEFAULT_COVER_PICTURE);
                        // }}
                        onClick={() => {
                          _toggleShowMediaModal(
                            true,
                            feed?._contents?.[0]?._content?.url,
                            "image"
                          );
                        }}
                        alt="Feed Img"
                        loading="lazy"
                      />
                    )}
                    {!isPublic && isLocked ? (
                      <div className="postLockedOverlay">
                        {/* <h3>
                            <i className="fa fa-image"/>{" "}
                            Locked Post
                          </h3> */}

                        <SvgIcons type="lock" className="iconLock" />

                        <div className="feedContentCount">
                          {contentTypeCount.image ? (
                            <div>
                              <i
                                className="fa fa-image"
                                style={{
                                  verticalAlign: -1,
                                  marginLeft: 1,
                                }}
                              />
                              <span>{contentTypeCount.image}</span>
                            </div>
                          ) : null}
                        </div>

                        {isSubscriptionRequired ? (
                          <Button
                            disabled={!feed?._influencer?.paymentEnabled}
                            className="paymentBtnTier"
                            onClick={() => _subscribe()}
                          >
                            Subscribe
                          </Button>
                        ) : (
                          <FeedPaymentTier
                            subscription={feed?.subscription}
                            influencer={feed?._influencer}
                            paymentFor={feed}
                            feedBundles={feedBundles}
                            requestUpdate={() => onTierUpgrade()}
                            unlockMethod={(amountToPay, card) =>
                              _unlockPost(amountToPay, card)
                            }
                            onPaymentComplete={onPaymentComplete}
                            feedIndex={feedIndex}
                          />
                        )}
                      </div>
                    ) : null}{" "}
                  </div>
                </CardBody>

                {/* goal div add here */}
                {goalDiv ? goalDiv : null}

                <CardFooter className={"footerFeed"}>
                  <div className="d-flex">
                    <Button
                      className={
                        "mr-2 mr-sm-3 " +
                        (isSubscriptionRequired ? "disabled-feed-options" : "")
                      }
                      onClick={() => _toggleLikePost()}
                    >
                      <i className={getLikeIconClassName(feed)} />
                    </Button>

                    <Button
                      className={
                        "mr-2 mr-sm-3 " +
                        (isSubscriptionRequired ? "disabled-feed-options" : "")
                      }
                      onClick={() => _toggleCommentSection()}
                    >
                      <i className={getCommentIconClassName(feed)} />
                    </Button>

                    <Button
                      className={
                        "mr-2 mr-sm-3 " +
                        (isSubscriptionRequired ? "disabled-feed-options" : "")
                      }
                      onClick={() => _toggleFeedTipModal(true)}
                      disabled={!feed?._influencer?.paymentEnabled}
                    >
                      <img
                        src="/assets/img/dollar.png"
                        alt="Dollar"
                        loading="lazy"
                      />
                    </Button>

                    {(!isPublic && isLocked) || isProfilePage ? null : (
                      <Button className="ml-auto mr-3">
                        <ImageQualitySetting
                          id={`single_image_${feed?._id}`}
                          imageQuality={imageQuality}
                          setImageQuality={setImageQuality}
                          isFeed={true}
                        />
                      </Button>
                    )}

                    <Button
                      className={
                        (!isPublic && isLocked) || isProfilePage
                          ? "ml-auto "
                          : "" +
                            (isSubscriptionRequired
                              ? feed?.isPublic && feed?.isFree
                                ? ""
                                : "disabled-feed-options"
                              : "")
                      }
                      title="Share"
                      onClick={() =>
                        copyPostPublicLink({
                          influencerUsername: feed?._influencer?.username,
                          postId: feed?._id,
                        })
                      }
                    >
                      <img
                        src="/assets/img/quick-link.png"
                        alt="share"
                        loading="lazy"
                        style={{ verticalAlign: -2 }}
                      />
                    </Button>

                    <Button
                      title="Report"
                      className={
                        "ml-3 ml-sm-3 " +
                        (isSubscriptionRequired ? "disabled-feed-options" : "")
                      }
                      onClick={() => _toggleFlagModal(true)}
                    >
                      <i className="fa fa-flag-o" />
                    </Button>
                  </div>

                  {feed?.likes || feed?.comments ? (
                    <div className="mt-1">
                      {feed?.likes ? (
                        <span>
                          {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
                        </span>
                      ) : null}
                      {feed?.comments ? (
                        <span>
                          {feed?.comments}{" "}
                          {feed?.comments > 1 ? "Comments" : "Comment"}
                        </span>
                      ) : null}
                    </div>
                  ) : null}

                  {/* post time */}
                  <div className="postTime-Feed">
                    {formatDate(feed?.startDate)}
                  </div>
                </CardFooter>
              </Card>
            );
          }
          case "audio": {
            // contains only one audio file
            return (
              <AudioViewer
                feed={feed}
                feedIndex={feedIndex}
                feedBundles={feedBundles}
                isSubscriptionRequired={isSubscriptionRequired}
                goToPostDetailsPage={_goToPostDetailsPage}
                isPublic={isPublic}
                isLocked={isLocked}
                contentTypeCount={contentTypeCount}
                goalDiv={goalDiv}
                toggleCommentSection={_toggleCommentSection}
                toggleLikePost={_toggleLikePost}
                toggleFlagModal={_toggleFlagModal}
                toggleFeedTipModal={_toggleFeedTipModal}
                unlockPost={_unlockPost}
                onPaymentComplete={onPaymentComplete}
                onTierUpgrade={onTierUpgrade}
                scrollToTop={scrollToTop}
              />
            );
          }
          case "video": {
            // contains only one video
            return (
              <VideoViewer
                feed={feed}
                feedIndex={feedIndex}
                feedBundles={feedBundles}
                isSubscriptionRequired={isSubscriptionRequired}
                goToPostDetailsPage={_goToPostDetailsPage}
                isPublic={isPublic}
                isLocked={isLocked}
                contentTypeCount={contentTypeCount}
                goalDiv={goalDiv}
                toggleCommentSection={_toggleCommentSection}
                toggleLikePost={_toggleLikePost}
                toggleFlagModal={_toggleFlagModal}
                toggleFeedTipModal={_toggleFeedTipModal}
                unlockPost={_unlockPost}
                onPaymentComplete={onPaymentComplete}
                onTierUpgrade={onTierUpgrade}
                scrollToTop={scrollToTop}
              />
            );
          }
          default:
        }
      } else if (feed?._contents?.length === 0) {
        // text only post
        return (
          <TextViewer
            feed={feed}
            isSubscriptionRequired={isSubscriptionRequired}
            goToPostDetailsPage={_goToPostDetailsPage}
            toggleCommentSection={_toggleCommentSection}
            toggleLikePost={_toggleLikePost}
            toggleFlagModal={_toggleFlagModal}
            toggleFeedTipModal={_toggleFeedTipModal}
            scrollToTop={scrollToTop}
          />
        );
      }
    } else if (feed?.category === "poll") {
      return (
        <PollViewer
          feed={feed}
          feedIndex={feedIndex}
          isSubscriptionRequired={isSubscriptionRequired}
          selectPollOption={selectPollOption}
          toggleCommentSection={_toggleCommentSection}
          toggleLikePost={_toggleLikePost}
          toggleFlagModal={_toggleFlagModal}
          goToPostDetailsPage={_goToPostDetailsPage}
          scrollToTop={scrollToTop}
        />
      );
    }

    return <div />;
  };

  const _toggleShowMediaModal = (
    isOpen = false,
    externalLink = null,
    contentType = null
  ) => {
    setShowMediaModal({
      isOpen,
      externalLink,
      contentType,
    });
  };

  // Payment Intent For Apple Pay
  // work for vault unlock
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await createPaymentIntent("/payment/intent/folder", {
          folderId: feed?._folder?._id,
          postId: feed?._id,
        });

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Payment Success For Apple Pay
  const _paymentSuccess = () => {
    _togglePaymentHoc();
    onPaymentComplete(feedIndex);
  };

  return (
    <>
      <div
        {...(lastElementRef ? { ref: lastElementRef } : {})}
        {...(customClassname ? { className: customClassname } : {})}
      >
        {_renderPost()}

        <PostCommentSection
          isOpen={isCommentSectionOpen}
          feed={feed}
          feedIndex={feedIndex}
          onComment={(index, isAdded) => onComment(index, isAdded)}
          onLikeComment={(id, isLiked) => _toggleLikeComment(id, isLiked)}
          commentIdLikeLoading={commentIdLikeLoading}
          comments={comments}
          postComment={(text) => _postComment(text)}
          getComments={() => _getAllComments()}
          isCommentFetching={commentsLoading}
        />
      </div>

      {lastElementRef && loadingFeeds && (
        <SkeletonLoading type={"post"} count={2} />
      )}

      {showMediaModal?.isOpen ? (
        <ShowMediaModal
          isOpen={showMediaModal.isOpen}
          imageQuality={imageQuality}
          externalLink={showMediaModal.externalLink}
          contentType={showMediaModal.contentType}
          toggle={() => _toggleShowMediaModal()}
        />
      ) : null}

      {/* // work for vault unlock */}
      <PaymentHoc
        // Apple pay relate
        content={{
          name: getConfiguredString("Vault Post"),
          price: paymentHoc.amount,
        }}
        createPaymentIntent={() => _createPaymentIntent()}
        onPaymentSuccess={() => _paymentSuccess()}
        // End here
        isVisible={paymentHoc.isOpen}
        amount={paymentHoc.amount}
        influencer={feed?._influencer}
        onPaymentConfirmation={(_, card) => _unlockPost(_, card)}
        onDismiss={() => _togglePaymentHoc()}
      />

      <FeedTipModal
        isOpen={feedTipModal.isOpen}
        toggle={() => _toggleFeedTipModal()}
        feed={feed}
        influencer={feed?._influencer}
      />

      <ReportPostModal
        isVisible={flagModal.isOpen}
        feed={feed}
        onSuccess={_onFlagConfirmation}
        onDismiss={() => _toggleFlagModal()}
      />

      <ContributeToGoalModal
        isVisible={contributeToGoalModal.isOpen}
        feed={feed}
        feedIndex={feedIndex}
        influencer={feed?._influencer}
        onTipSuccess={() => _onContributeToGoalSuccess(feedIndex)}
        onDismiss={() => _toggleContributeToGoalModal()}
      />
    </>
  );
};

export default FeedViewer;
