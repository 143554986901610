import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Label,
} from "reactstrap";
import { deepClone, showToast, uploadFileOnServer } from "../../helper-methods";
import { getMediaContents, saveContent } from "../../http-calls";
import Pagination from "react-js-pagination";
import { UPLOAD_LIMIT, UPLOAD_LIMIT_TEXT } from "../../config";
import SkeletonLoading from "../SkeletonLoading";

class MediaLibraryModal extends Component {
  state = {
    activeTab: "1",
    activeIndex: 0,
    imageContents: [],
    videoContents: [],
    audioContents: [],
    selectedImages: [],
    selectedVideos: [],
    selectedAudios: [],
    uploadMedias: [],
    contents: [],
    totalImages: 0,
    totalVideos: 0,
    totalAudios: 0,
    filter: {
      contentType: "image",
      skip: 0,
      limit: 12,
      pageNumber: 1,
    },
    disabledSubmitBtn: false,
    loading: false,
    contentBreak: {},
  };

  // Toggle for Tab change
  _toggleTab = (activeTab = "4", contentType = null) => {
    let { filter } = deepClone(this.state);

    if (contentType) {
      filter.contentType = contentType;
      filter.skip = 0;
      filter.pageNumber = 1;
      this.setState(
        {
          activeTab,
          filter,
          contentBreak: {},
        },
        () => {
          this._fetchMediaContents();
        }
      );
    } else {
      this.setState({
        activeTab,
      });
    }
  };

  _closeThisModal = () => {
    this._resetState();
    this.props.toggle();
  };

  _resetState = () => {
    this.setState({
      selectedImages: [],
      selectedVideos: [],
      selectedAudios: [],
      uploadMedias: [],
      contents: [],
      disabledSubmitBtn: false,
      loading: false,
      contentBreak: {},
    });
  };

  componentDidMount = () => {
    this._fetchMediaContents();
  };

  // Fetch media for Image, Video and Audio
  _fetchMediaContents = async () => {
    let {
      imageContents,
      audioContents,
      videoContents,
      totalAudios,
      totalVideos,
      totalImages,
      filter,
    } = deepClone(this.state);

    try {
      let response;
      switch (filter.contentType) {
        case "image": {
          this.setState({ loading: true });
          response = await getMediaContents(filter);
          imageContents =
            response.contents && response.contents.length
              ? response.contents
              : [];
          totalImages = response.imageCount ? response.imageCount : 0;
          totalVideos = response.videoCount ? response.videoCount : 0;
          totalAudios = response.audioCount ? response.audioCount : 0;
          this.setState({
            imageContents,
            totalImages,
            totalVideos,
            totalAudios,
            loading: false,
            contentBreak: {},
          });
          break;
        }
        case "video": {
          this.setState({ loading: true });
          response = await getMediaContents(this.state.filter);
          videoContents =
            response.contents && response.contents.length
              ? response.contents
              : [];
          totalImages = response.imageCount ? response.imageCount : 0;
          totalVideos = response.videoCount ? response.videoCount : 0;
          totalAudios = response.audioCount ? response.audioCount : 0;
          this.setState({
            videoContents,
            totalImages,
            totalVideos,
            totalAudios,
            loading: false,
            contentBreak: {},
          });
          break;
        }
        case "audio": {
          this.setState({ loading: true });
          response = await getMediaContents(this.state.filter);
          audioContents =
            response.contents && response.contents.length
              ? response.contents
              : [];
          totalImages = response.imageCount ? response.imageCount : 0;
          totalVideos = response.videoCount ? response.videoCount : 0;
          totalAudios = response.audioCount ? response.audioCount : 0;
          this.setState({
            audioContents,
            totalImages,
            totalVideos,
            totalAudios,
            loading: false,
            contentBreak: {},
          });
          break;
        }
        default:
      }
    } catch (error) {
      this.setState({ loading: false });

      console.log("error >>", error);
    }
  };

  _isValidUploadLimit = (
    uploadMedias = [],
    selectedImages = [],
    selectedVideos = []
  ) => {
    return new Promise((resolve) => {
      const { imageLimit, videoLimit, uploadedFiles } = this.props;

      const localImageCount = uploadMedias.filter((media) =>
        media.type.includes("image")
      ).length;
      const localVideoCount = uploadMedias.filter((media) =>
        media.type.includes("video")
      ).length;

      const uploadedImageCount = uploadedFiles.filter((media) =>
        media.contentType.includes("image")
      ).length;
      const uploadedVideoCount = uploadedFiles.filter((media) =>
        media.contentType.includes("video")
      ).length;

      const totalImages =
        localImageCount + uploadedImageCount + selectedImages.length;
      const totalVideos =
        localVideoCount + uploadedVideoCount + selectedVideos.length;

      if (totalImages > imageLimit || totalVideos > videoLimit) resolve(false);
      else resolve(true);
    });
  };

  // Select media for each tab
  _toggleSelect = async (contentId, contentType) => {
    let {
      uploadMedias,
      imageContents,
      audioContents,
      videoContents,
      selectedImages,
      selectedVideos,
      selectedAudios,
    } = deepClone(this.state);

    const { isAudioUpload, isUploadLimit, imageLimit, videoLimit } = this.props;

    switch (contentType) {
      case "image": {
        // Find selected Image
        let selectedImage = imageContents.find(
          (image) => image._id === contentId
        );

        if (selectedImages.find((image) => image._id === contentId)) {
          // Find selected image index in images array
          // and unselect
          let indexOfImage = selectedImages.findIndex(
            (image) => image._id === selectedImage._id
          );
          selectedImages.splice(indexOfImage, 1);
        } else {
          // check for multi image select support option
          if (this.props.isMultipleImages) {
            selectedImages.push(selectedImage);
          } else {
            selectedImages.splice(0, 1, selectedImage);
          }
        }

        if (isUploadLimit) {
          const isValid = await this._isValidUploadLimit(
            uploadMedias,
            selectedImages,
            selectedVideos
          );
          if (!isValid) {
            showToast(
              `Only ${imageLimit} images & ${videoLimit} video are allowed`,
              "error"
            );
            return;
          }
        }

        this.setState({ imageContents, selectedImages });
        break;
      }
      case "video": {
        // Find selected Video
        let selectedVideo = videoContents.find(
          (video) => video._id === contentId
        );

        if (selectedVideos.find((image) => image._id === contentId)) {
          // Find selected video index in videos array
          // and unselect
          let indexOfImage = selectedVideos.findIndex(
            (image) => image._id === selectedVideo._id
          );
          selectedVideos.splice(indexOfImage, 1);
        } else {
          // check for multi videos select support option
          if (this.props.isMultipleVideos) {
            selectedVideos.push(selectedVideo);
          } else {
            selectedVideos.splice(0, 1, selectedVideo);
          }
        }

        if (isUploadLimit) {
          const isValid = await this._isValidUploadLimit(
            uploadMedias,
            selectedImages,
            selectedVideos
          );
          if (!isValid) {
            showToast(
              `Only ${imageLimit} images & ${videoLimit} video are allowed`,
              "error"
            );
            return;
          }
        }

        this.setState({ videoContents, selectedVideos });
        break;
      }
      case "audio": {
        if (!isAudioUpload) {
          showToast("Audio file not supported", "error");
          return;
        }

        // Find selected Audio
        let selectedAudio = audioContents.find(
          (audio) => audio._id === contentId
        );

        if (selectedAudios.find((image) => image._id === contentId)) {
          // Find selected audio index in audios array
          // and unselect
          let indexOfImage = selectedAudios.findIndex(
            (image) => image._id === selectedAudio._id
          );
          selectedAudios.splice(indexOfImage, 1);
        } else {
          // check for multi audios select support option
          if (this.props.isMultipleAudios) {
            selectedAudios.push(selectedAudio);
          } else {
            selectedAudios.splice(0, 1, selectedAudio);
          }
        }

        this.setState({ audioContents, selectedAudios });
        break;
      }
      default:
    }
  };

  // media content watermarked and craete blob
  _uploadFile = async (e) => {
    let { uploadMedias, selectedImages, selectedVideos } = deepClone(
      this.state
    );

    const { isAudioUpload, isUploadLimit, imageLimit, videoLimit } = this.props;

    let fileList =
      e && e.target && e.target.files && Array.from(e.target.files);

    if (fileList?.length) {
      if (isUploadLimit) {
        const isValid = await this._isValidUploadLimit(
          [...fileList, ...uploadMedias],
          selectedImages,
          selectedVideos
        );
        if (!isValid) {
          showToast(
            `Only ${imageLimit} images & ${videoLimit} video are allowed`,
            "error"
          );
          return;
        }
      }

      for (let i = 0; i < fileList.length; i++) {
        // if (
        //   !isValidVideoFileType(fileList[i]) ||
        //   !isValidImageFileType(fileList[i])
        // )
        //   continue;

        const type = fileList[i].type.split("/")[0];

        //image
        if (fileList[i] && fileList[i].type.includes("image")) {
          let image = {
            previewBlob: URL.createObjectURL(fileList[i]),
            uploadData: fileList[i],
            type,
          };

          uploadMedias.push(image);
        } else {
          if (type === "audio" && !isAudioUpload) {
            showToast("Audio file not supported", "error");
            return;
          }

          // video and audio // doc
          if (fileList[i].size <= UPLOAD_LIMIT) {
            let image = {
              previewBlob: URL.createObjectURL(fileList[i]),
              uploadData: fileList[i],
              type: type === "application" ? "doc" : type,
            };

            uploadMedias.push(image);
          } else {
            showToast(
              `Files above ${UPLOAD_LIMIT_TEXT} are currently not supported`,
              "error"
            );
          }
        }
      }
    }

    this.setState({ uploadMedias });
  };

  _onLoadedMetadata = (target, index) => {
    let { uploadMedias } = deepClone(this.state);
    uploadMedias[index]["duration"] = target.duration;
    this.setState({ uploadMedias });
  };

  _deleteContent = (index) => {
    let { uploadMedias } = deepClone(this.state);
    uploadMedias.splice(index, 1);
    this.setState({ uploadMedias });
  };

  _submitSelectedMedia = async () => {
    let { contents, selectedImages, selectedVideos, selectedAudios } =
      deepClone(this.state);

    this.setState({ disabledSubmitBtn: true }, () => {
      this.props.toggle();
    });

    // Concat all types of media in contents array
    contents = selectedImages
      .concat(selectedVideos)
      .concat(selectedAudios)
      .map((media) => ({
        contentType: media.contentType,
        url: media.url,
        hlsLink: media.hls,
        thumbnail: media.thumbnail,
        _id: media._id,
      }));

    this.setState({ contents }, async () => {
      if (this.state.uploadMedias?.length) {
        // if user upload content for the first time
        this._uploadMedias();
      } else {
        // if user not upload content
        this._createContentsData();
      }
    });
  };

  _saveContent = (contents = []) => {
    saveContent({ contents })
      .then((res) => {
        const uploadedContents = res.contents.map((content) => {
          return {
            contentType: content.contentType,
            url: content.url,
            hlsLink: content.hls,
            thumbnail: content.thumbnail,
            _id: content._id,
            extension: content.extension,
          };
        });
        this._createContentsData(uploadedContents);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  // Upload media in aws s3 and get url
  _uploadMedias = async () => {
    let { uploadMedias } = deepClone(this.state);

    const response = await uploadFileOnServer(uploadMedias);

    console.log("response>>", response);

    this._saveContent(response);
  };

  // Merge all content in one array and pass to parent component
  _createContentsData = (data = []) => {
    let { contents } = deepClone(this.state);
    contents = contents.concat(data);

    this.setState({ contents, disabledSubmitBtn: false }, () => {
      this.props.uploadContents(contents);
      this._closeThisModal();
    });
  };

  _handlePageChange = (pageNum) => {
    let { filter } = deepClone(this.state);
    filter["pageNumber"] = pageNum;
    filter["skip"] = (pageNum - 1) * filter["limit"];
    this.setState({ filter }, () => {
      this._fetchMediaContents();
    });
  };

  _getMediaNumber = (type) => {
    const { uploadMedias } = this.state;
    return uploadMedias.filter((media) => media.type.includes(type)).length;
  };

  _getTotalItem = () => {
    let totalItem;
    switch (this.state.filter.contentType) {
      case "image":
        totalItem = this.state.totalImages;
        break;
      case "video":
        totalItem = this.state.totalVideos;
        break;
      case "audio":
        totalItem = this.state.totalAudios;
        break;
      default:
        totalItem = 0;
        break;
    }
    return totalItem;
  };

  render() {
    const {
      imageContents,
      audioContents,
      videoContents,
      selectedImages,
      selectedVideos,
      selectedAudios,
      totalAudios,
      totalVideos,
      totalImages,
      uploadMedias,
      disabledSubmitBtn,
      filter,
      loading,
      activeTab,
      contentBreak,
    } = this.state;

    const countLocalUploadedFile =
      selectedImages?.length +
      selectedVideos?.length +
      selectedAudios?.length +
      uploadMedias?.length;

    const { isAudioUpload, isPdfUpload, isMultipleUpload } = this.props;

    const MediaLibraryLoading = (
      <>
        {[...Array(6)].map((_, index) => (
          <Col
            xs={6}
            md={4}
            lg={3}
            className="px-1"
            key={`media_video_loading_${index}`}
          >
            <SkeletonLoading type="box" count={1} width={150} height={130} />
          </Col>
        ))}
      </>
    );

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeThisModal()}
        className="modal-dialog-centered modal-xl modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeThisModal()}>
          Media Library
        </ModalHeader>
        <ModalBody>
          <Nav tabs className="customTabs mediaLibraryTab">
            <NavItem>
              <NavLink
                active={activeTab === "1"}
                onClick={() => {
                  this._toggleTab("1", "image");
                }}
              >
                Photos <span>{`(${totalImages})`}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "2"}
                onClick={() => {
                  this._toggleTab("2", "video");
                }}
              >
                Videos <span>{`(${totalVideos})`}</span>
              </NavLink>
            </NavItem>

            {isAudioUpload ? (
              <NavItem>
                <NavLink
                  active={activeTab === "3"}
                  onClick={() => {
                    this._toggleTab("3", "audio");
                  }}
                >
                  Audio <span>{`(${totalAudios})`}</span>
                </NavLink>
              </NavItem>
            ) : null}

            <NavItem>
              <NavLink
                active={activeTab === "4"}
                onClick={() => {
                  this._toggleTab("4");
                }}
              >
                Upload New
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="tabContentMediaLibrary">
            <TabPane tabId="1">
              <Row className="mx-0">
                {imageContents && imageContents.length ? (
                  imageContents.map((image, index) => (
                    <Col xs={6} md={4} lg={3} key={index} className="px-1">
                      {/* selected file will look like this */}
                      {contentBreak[index] ? (
                        <div className="mediaLibraryFilesWrap">
                          {/* if the media is not loading */}
                          <Button
                            className="reloadBtn"
                            onClick={() =>
                              this.setState({
                                contentBreak: {
                                  ...contentBreak,
                                  [index]: false,
                                },
                              })
                            }
                          >
                            <i className="fa fa-refresh" />
                          </Button>
                        </div>
                      ) : (
                        <div
                          className="mediaLibraryFilesWrap"
                          onClick={() => this._toggleSelect(image._id, "image")}
                        >
                          <img
                            src={image.url}
                            alt="Media File"
                            className="mediaLibraryFiles"
                            onError={() =>
                              this.setState({
                                contentBreak: {
                                  ...contentBreak,
                                  [index]: true,
                                },
                              })
                            }
                            loading="lazy"
                          />
                          {selectedImages.find(
                            (selectedImage) => selectedImage._id === image._id
                          ) ? (
                            <div className="mediaFileSelected">
                              <i className="fa fa-check-circle" />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Col>
                  ))
                ) : loading ? (
                  MediaLibraryLoading
                ) : (
                  <Col xs={12}>
                    <div className="noContentFound">Image not found</div>
                  </Col>
                )}
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="mx-0">
                {videoContents && videoContents.length ? (
                  videoContents.map((video, index) => (
                    <Col xs={6} md={4} lg={3} key={index} className="px-1">
                      {contentBreak[index] ? (
                        <div className="mediaLibraryFilesWrap">
                          {/* if the media is not loading */}
                          <Button
                            className="reloadBtn"
                            onClick={() =>
                              this.setState({
                                contentBreak: {
                                  ...contentBreak,
                                  [index]: false,
                                },
                              })
                            }
                          >
                            <i className="fa fa-refresh" />
                          </Button>
                        </div>
                      ) : (
                        <div
                          className="mediaLibraryFilesWrap"
                          onClick={() => this._toggleSelect(video._id, "video")}
                        >
                          <video
                            controls
                            controlsList="nodownload"
                            className="mediaLibraryFiles"
                            poster={video.thumbnail}
                            src={video.url}
                            onError={() =>
                              this.setState({
                                contentBreak: {
                                  ...contentBreak,
                                  [index]: true,
                                },
                              })
                            }
                          />
                          {selectedVideos.find(
                            (selectedVideo) => selectedVideo._id === video._id
                          ) ? (
                            <div className="mediaFileSelected">
                              <i className="fa fa-check-circle" />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Col>
                  ))
                ) : loading ? (
                  MediaLibraryLoading
                ) : (
                  <Col xs={12}>
                    <div className="noContentFound">Video not found</div>
                  </Col>
                )}
              </Row>
            </TabPane>

            {isAudioUpload ? (
              <TabPane tabId="3">
                <Row className="mx-0">
                  {audioContents && audioContents.length ? (
                    audioContents.map((audio, index) => (
                      <Col xs={6} md={4} lg={3} key={index} className="px-1">
                        <div
                          className="mediaLibraryFilesWrap"
                          onClick={() => this._toggleSelect(audio._id, "audio")}
                        >
                          <div className="mediaLibraryFiles d-flex align-items-center justify-content-center">
                            <audio
                              ref="audioSource"
                              controls="controls"
                              src={audio.url}
                            />
                            {selectedAudios.find(
                              (selectedAudio) => selectedAudio._id === audio._id
                            ) ? (
                              <div className="mediaFileSelected">
                                <i className="fa fa-check-circle" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : loading ? (
                    MediaLibraryLoading
                  ) : (
                    <Col xs={12}>
                      <div className="noContentFound">Audio not found</div>
                    </Col>
                  )}
                </Row>
              </TabPane>
            ) : null}

            <TabPane tabId="4">
              <div className="mediaFileUploadWrap">
                <Label className="btn themeBtn">
                  <input
                    type="file"
                    accept={`image/*,video/*,${
                      isAudioUpload ? "audio/*" : ""
                    },${isPdfUpload ? ".pdf" : ""}`}
                    multiple={
                      !this.props.hasOwnProperty("isMultipleUpload") ||
                      (this.props.hasOwnProperty("isMultipleUpload") &&
                        isMultipleUpload)
                    }
                    onChange={(e) => this._uploadFile(e)}
                    style={{ display: "none" }}
                  />
                  <span>Upload</span>
                </Label>
              </div>

              <div className="msgPreviewWrap mt-2">
                {uploadMedias && uploadMedias.length
                  ? uploadMedias.map((media, index) =>
                      media["type"].split("/")[0] === "application" ||
                      media["type"].split("/")[0] === "image" ||
                      media["type"].split("/")[0] === "audio" ? (
                        <div className="msgPreview" key={index}>
                          {media["type"].split("/")[0] === "application" ? (
                            <img
                              src="/assets/img/pdf_icon.png"
                              alt="PDF"
                              style={{ height: 28, width: "unset" }}
                              loading="lazy"
                            />
                          ) : media["type"].split("/")[0] === "audio" ? (
                            <img
                              src="/assets/img/microphone.png"
                              alt="Microphone"
                              style={{ height: 28, width: "unset" }}
                              loading="lazy"
                            />
                          ) : (
                            <img
                              key={index}
                              src={media["previewBlob"]}
                              alt="Preview"
                              loading="lazy"
                            />
                          )}
                          <Button
                            onClick={() => this._deleteContent(index)}
                            className="deletePreview"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      ) : (
                        <div className="msgPreview" key={index}>
                          <video
                            key={index}
                            src={media["previewBlob"]}
                            onLoadedMetadata={(e) =>
                              this._onLoadedMetadata(e.target, index)
                            }
                          />
                          <Button
                            onClick={() => this._deleteContent(index)}
                            className="deletePreview"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      )
                    )
                  : null}
              </div>
            </TabPane>
          </TabContent>
          {activeTab !== "4" && this._getTotalItem() ? (
            <div className="mediaLibraryPagination">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="Prev"
                nextPageText="Next"
                activePage={filter.pageNumber}
                itemsCountPerPage={filter.limit}
                totalItemsCount={this._getTotalItem()}
                pageRangeDisplayed={3}
                onChange={this._handlePageChange}
              />
            </div>
          ) : null}
        </ModalBody>

        <ModalFooter className="d-flex justify-content-between">
          {/* text to indicate the number of files selected */}
          <div className="filesSelectedTxt mr-0 mr-sm-3">
            {countLocalUploadedFile ? (
              <div className="mr-0 mr-sm-1" style={{ color: "#333" }}>
                <span>{countLocalUploadedFile}</span>{" "}
                {countLocalUploadedFile > 1 ? "files" : "file"} selected
              </div>
            ) : null}
            <div>
              {countLocalUploadedFile ? (
                <>
                  (
                  {selectedImages.length ||
                  (uploadMedias.length && this._getMediaNumber("image")) ? (
                    <span>
                      {selectedImages.length + this._getMediaNumber("image")}{" "}
                      <i className="fa fa-image" />{" "}
                    </span>
                  ) : null}
                  {selectedVideos.length ||
                  (uploadMedias.length && this._getMediaNumber("video")) ? (
                    <span>
                      {selectedVideos.length + this._getMediaNumber("video")}{" "}
                      <i className="fa fa-video-camera" />{" "}
                    </span>
                  ) : null}
                  {selectedAudios.length ||
                  (uploadMedias.length && this._getMediaNumber("audio")) ? (
                    <span>
                      {selectedAudios.length + this._getMediaNumber("audio")}{" "}
                      <i className="fa fa-microphone" />{" "}
                    </span>
                  ) : null}
                  {uploadMedias.length &&
                  this._getMediaNumber("application") ? (
                    <span>
                      {this._getMediaNumber("application")}{" "}
                      <i className="fa fa-file-pdf-o" />
                    </span>
                  ) : null}
                  )
                </>
              ) : null}
            </div>
          </div>
          <div>
            <Button
              className="modalBtnCancel"
              onClick={() => this._closeThisModal()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={() => this._submitSelectedMedia()}
              disabled={disabledSubmitBtn}
            >
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default MediaLibraryModal;
